export const fetchStores = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  authToken,
}: {
  apiUrl?: string
  authToken: string
}): Promise<Array<Store>> => {
  const res = await fetch(`${apiUrl}/v1/partners?poses=true&printers=true`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': authToken,
    },
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const updateCurrentItems = async ({
  item,
  id = null,
  locale = 'fr',
}: {
  item: string
  id?: number | string
  locale?: string
}) => {
  const res = await fetch(
    `/${locale}/telesales/current_${item}?id=${id || ''}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    }
  )
  if (!res?.ok) throw Error
  const { message } = await res.json()
  return message
}
