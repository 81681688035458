import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import Legals from './Legals/Legals'
import legalsLinks from './legalsLinks.json'

import {
  Container,
  Link,
  LoadingDots,
  Logo,
  Modal,
  Separator,
} from '@components/ui'

import MediaQueries from '@components/common/MediaQueries'

import { MODALS } from '@constants'

import cn from 'classnames'
import s from './LayoutLite.module.css'
import { useRouter } from 'next/router'

const Loading = () => (
  <div className="w-80 h-80 m-auto flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const NotificationModal = dynamic(
  () => import('@components/common/NotificationModal'),
  {
    loading: () => <Loading />,
  }
)

const TelesalesModal = dynamic(
  () => import('@components/common/TelesalesModal'),
  {
    loading: () => <Loading />,
  }
)

interface Props {
  pageProps: {
    alternates?: Array<Alternate>
    countries?: Array<Country>
    currencies: Array<Currency>
    locale: string
    navigation?: NavigationItem[]
    topSeparator?: boolean
    page?: Page
    disableLogoRedirect?: boolean
  }
}

const LayoutLite: FC<Props> = ({ children, pageProps }) => {
  const { t } = useTranslation()
  const { disableLogoRedirect } = pageProps
  const {
    displayModal,
    closeModal,
    modalView,
    modalViewProps,
    isSiteReverted,
    enableSiteReverted,
  } = useUI()
  const {
    locale,
    query: { revert },
  } = useRouter()

  enableSiteReverted(
    typeof revert !== 'undefined'
      ? true
      : typeof window !== 'undefined'
      ? window?.Translatable?.currentLang
      : locale
  )

  return (
    <div className={s.root} dir={isSiteReverted ? 'rtl' : 'ltr'}>
      <header className={s.header}>
        <Container className={s.container}>
          {disableLogoRedirect ? (
            <Logo className={s.logo} />
          ) : (
            <Link
              next={false}
              href={`/${locale}`}
              className={s.headerCenter}
              aria-label={t('layout:header.ariaGoToHome')}
            >
              <Logo className={s.logo} />
              <p className={s.catchPhrase}>{t('layout:header.catchPhrase')}</p>
            </Link>
          )}
        </Container>
      </header>

      <Separator className={s.separator} />
      <main className="fit">{children}</main>

      <footer className={s.bottom}>
        <MediaQueries hidden={['xs', 'sm']}>
          <div className={s.legals}>
            <Legals links={legalsLinks} />
          </div>
        </MediaQueries>
        <div className={s.copyright}>
          <span>
            {t('layout:footer.copyright', { year: new Date().getFullYear() })}
          </span>
        </div>
      </footer>
      <Modal
        open={
          displayModal &&
          [MODALS.NOTIFICATION, MODALS.TELESALES].includes(modalView)
        }
        onClose={closeModal}
        className={cn({ [s.no_overflow]: modalView === MODALS.TELESALES })}
      >
        {modalView === MODALS.NOTIFICATION && (
          <NotificationModal message={modalViewProps?.message || ''} />
        )}
        {modalView === MODALS.TELESALES && (
          <TelesalesModal countries={pageProps.countries} />
        )}
      </Modal>
    </div>
  )
}

export default LayoutLite
