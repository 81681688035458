import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { DynamicInput, Error, FormErrors } from '@components/form'

import { EMAIL_REGEXP } from '@constants'
import { getCookie } from '@utils/cookies'

import cn from 'classnames'
import s from './EmailInput.module.css'

interface EmailInputProps {
  id: string
  name: string
  emailFromV2?: string | null
  required?: boolean
  errors?: any
  setErrors?: any
  handleFormData?: any
}

const EmailInput: FC<EmailInputProps> = ({
  id,
  name,
  emailFromV2,
  required = false,
  errors,
  setErrors,
  handleFormData,
}) => {
  const { t } = useTranslation()
  const errorsObject = FormErrors()

  const [dataInput, setDataInput] = useState<{
    data: string
    name: string
  }>({ data: '', name: '' })

  const handleOnBlur = () => {
    if (dataInput?.data?.length === 0) {
      required &&
        setErrors({
          ...errors,
          [name]: errorsObject?.emptyField,
        })
      return
    }

    if (dataInput?.data && !dataInput.data.trim().match(EMAIL_REGEXP)) {
      required &&
        setErrors({
          ...errors,
          [name]: errorsObject?.username,
        })
      return
    }

    handleFormData({
      mandatory: required ? 'mandatory' : 'optional',
      key: dataInput?.name,
      value: dataInput?.data?.trim().toLocaleLowerCase(),
    })

    required &&
      setErrors({
        ...errors,
        [name]: 'ok',
      })
  }

  useEffect(() => {
    required &&
      setErrors({
        ...errors,
        [name]: null,
      })
    handleOnBlur()
  }, [dataInput?.data])

  useEffect(() => {
    if (!emailFromV2) return null
    setDataInput({
      ...dataInput,
      data: emailFromV2,
      name: 'username',
    })
    handleOnBlur()
  }, [])

  useEffect(() => {
    const fbCookie = getCookie('SML_FB_CONNECT', document?.cookie)
    if (!fbCookie) return null
    const fbInfos = JSON.parse(fbCookie)
    setDataInput({
      ...dataInput,
      data: fbInfos?.email,
      name: 'username',
    })
    handleFormData({
      mandatory: required ? 'mandatory' : 'optional',
      key: 'facebookId',
      value: fbInfos?.id,
    })
    handleOnBlur()
  }, [])

  return (
    <DynamicInput
      id={id}
      type="text"
      inputName={name}
      label={t('layout:header.modalLogin.input.mail') + (required ? '*' : '')}
      className={cn({
        [s.errorInput]:
          errors[name]?.type === Error.InvalidEmail ||
          errors[name]?.type === Error.EmptyField ||
          errors[name]?.type === Error.EmailAlreadyExists,
      })}
      ariaLabel={t('layout:header.modalLogin.input.mail')}
      value={dataInput?.data}
      onChange={(e) => {
        setDataInput({
          ...dataInput,
          data: e.target.value,
          name: e.target.name,
        })
      }}
      errors={errors}
      onBlur={handleOnBlur}
      errorState={errors[name]}
    />
  )
}

export default EmailInput
