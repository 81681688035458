import { DEFAULT_COUNTRY } from '@constants'

let navigations = {}

export const fetchNavigation = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
  countryCode = DEFAULT_COUNTRY,
  sales = [],
  roles = [],
  headers,
}: {
  apiUrl?: string
  headers?: Headers
  locale: string
  countryCode: string
  sales?: number[]
  roles: string[]
}): Promise<NavigationItem[]> => {
  const params = new URLSearchParams({
    locale,
    countryCode,
  })
  sales.forEach((sale) => {
    params.append('sales[]', sale.toString())
  })

  const key = params.toString()

  /*if (navigations[key] && roles.indexOf('ROLE_TEST_DISCOUNT_CAMPAIGN') === -1) {
    return navigations[key]
  }*/

  const res = await fetch(`${apiUrl}/v1/navigation?${key}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers,
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  // navigations[key] = data
  return data
}
