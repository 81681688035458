import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { Loupe } from '@components/icons'

export interface ButtonSearchBarProps {
  id: string
  className?: string
}

const ButtonSearchBar: FC<ButtonSearchBarProps> = ({ className, id }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  return (
    <div id={id} className={className}>
      <a
        href={`/${locale}/search`}
        aria-label={t('layout:header.search.placeholder')}
      >
        <Loupe width="1.5rem" height="1.5rem" aria-hidden />
      </a>
    </div>
  )
}

export default ButtonSearchBar
