import { BASKET_ERRORS } from '@constants'
import { parse } from 'node-html-parser'

export const deleteItemFromBasket = async ({
  sku,
  locale,
  quantity,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  sku: string
  quantity?: number
  locale: string
  apiUrl?: string
}): Promise<Basket> => {
  const res = await fetch(`${apiUrl}/v1/basket`, {
    method: 'DELETE',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, locale, quantity }),
  })
  if (!res?.ok) throw Error
  const { data } = await res.json()
  return data
}

export const addItemToBasket = async ({
  sku,
  locale,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  sku: string
  locale: string
  apiUrl?: string
}): Promise<Basket> => {
  const res = await fetch(`${apiUrl}/v1/basket`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sku, locale }),
  })
  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }
  const { data } = await res.json()
  return data
}

export const getPriceBasket = async ({
  token,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  token: string
  apiUrl?: string
}): Promise<Basket> => {
  const res = await fetch(`${apiUrl}/basket/create`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': token,
    },
    body: JSON.stringify({}),
  })
  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }
  const { data } = await res.json()
  return data
}

export const getBasket = async ({
  basketId,
  anonymousToken,
  authToken = null,
  locked = false,
  locale,
  currency,
  items = false,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  basketId: string
  anonymousToken: string
  authToken?: string
  locked?: boolean
  locale: string
  currency: string
  items?: boolean
  apiUrl?: string
}): Promise<BasketBuy> => {
  const res = await fetch(
    `${apiUrl}/v1/basket/${basketId}?locale=${locale}&country=fr&currency=${currency}&items=${items}&locked=${locked}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ANONYMOUS-TOKEN': anonymousToken,
        'X-AUTH-TOKEN': authToken,
      },
    }
  )

  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }

  const { data } = await res.json()

  return data
}

export const getDetailedBasket = async ({
  orderId,
  authToken = null,
  locale,
  country,
  currency,
  convertPrice = false,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  orderId: string
  authToken: string
  locked?: boolean
  locale: string
  country: string
  currency?: string
  convertPrice?: boolean
  items?: boolean
  apiUrl?: string
}): Promise<any> => {
  const res = await fetch(
    `${apiUrl}/v1/detailed-basket/${orderId}?locale=${locale}${
      currency ? `&currency=${currency}` : ''
    }&country=${country}&convertPrice=${convertPrice ? 1 : 0}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': authToken,
      },
    }
  )

  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }

  const { data } = await res.json()

  return data
}

export const getSummaryAmounts = async ({
  basketId,
  anonymousToken,
  authToken = null,
  currencyCode = 'EUR',
  locale = 'fr',
  convertPrice,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  basketId: string
  anonymousToken: string
  authToken?: string
  currencyCode?: string
  locale?: string
  convertPrice: boolean
  apiUrl?: string
}): Promise<SummaryAmounts> => {
  const res = await fetch(
    `${apiUrl}/v1/basket/${basketId}/summary-amounts?locale=${locale}&currencyCode=${currencyCode}&convertPrice=${convertPrice}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ANONYMOUS-TOKEN': anonymousToken,
        'X-AUTH-TOKEN': authToken,
      },
    }
  )

  if (!res?.ok) {
    const { messageKey } = await res.json()
    throw messageKey
  }

  const { data } = await res.json()

  return data
}

export const getHTMLComponent = async ({
  locale = 'fr',
  ua,
  cookie,
  siteUrl = process.env.NEXT_PUBLIC_SMALLABLE_INTERNAL_URL || '',
  slug = '',
}: {
  locale: string
  ua: string
  cookie: string
  siteUrl?: string
  slug?: string
}): Promise<string> => {
  const isTranslatableLoaded =
    (typeof window !== 'undefined' && window.Translatable?.initialized) || false

  const newLocale = isTranslatableLoaded
    ? `${window.Translatable.currentLang}`
    : locale

  const res = await fetch(`${siteUrl}/${newLocale}/${slug}`, {
    method: 'GET',
    headers: {
      Accept: 'text/html',
      Cookie: cookie,
      'User-Agent': ua,
    },
  })
  const html = await res.text()

  return html
}

export const getUpdatedContent = async ({
  locale,
  siteUrl,
  slug,
}: {
  locale: string
  siteUrl: string
  slug: string
}): Promise<string> => {
  const isTranslatableLoaded =
    (typeof window !== 'undefined' && window.Translatable?.initialized) || false

  const newLocale = isTranslatableLoaded
    ? `${window.Translatable.currentLang}`
    : locale

  const res = await fetch(`${siteUrl}/${newLocale}/${slug}`, {
    method: 'GET',
    headers: {
      Accept: 'text/html',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  const html = await res.text()

  return html
}

export const getCarriers = ({ htmlDoc }: { htmlDoc: string }) => {
  const html = parse(htmlDoc) as any as HTMLElement
  const carrierNodes = Array.from(html.querySelectorAll('.-car-sel .fake-rad'))

  const carriers = carrierNodes.map((c) => ({
    id: c.getAttribute('data-id'),
    selected: c.classList.toString().includes('checked'),
    type: c.getAttribute('data-delivery-mode'),
    price: c.querySelector('.c-price').getAttribute('data-qa-value'),
  }))

  return carriers
}

export const selectCheapestCarrier = async ({
  carriers,
  locale,
  cookie,
  ua,
  siteUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL,
}: {
  carriers: any
  locale: string
  cookie: string
  ua: string
  siteUrl?: string
}): Promise<boolean> => {
  if (!carriers.length) return false
  // Check if selected carrier is the cheapest
  const cheapestCarrier = carriers.reduce((a, b) =>
    parseInt(a.price) < parseInt(b.price) ? a : b
  )
  if (cheapestCarrier.selected) return true

  const res = await updateCarrier({
    locale: locale,
    cookie,
    ua,
    siteUrl,
    carrierId: cheapestCarrier.id,
  })

  return res.status !== 200
}

export const updateCarrier = async ({
  locale,
  carrierId,
  ua,
  cookie,
  siteUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL,
}: {
  locale: string
  carrierId: string
  ua: string
  cookie: string
  siteUrl?: string
}): Promise<Response> => {
  try {
    const res = await fetch(
      `${siteUrl}/${locale}/carrier/update/${carrierId}/L4`,
      {
        method: 'GET',
        headers: {
          Accept: 'text/html',
          Cookie: cookie,
          'User-Agent': ua,
        },
      }
    )
    return res
  } catch (error) {
    console.error(error)
  }
}

export const validateBasket = async ({
  anonymousToken,
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
}: {
  anonymousToken: string
  apiUrl?: string
}): Promise<ValidateBasketResponse> => {
  try {
    const res = await fetch(`${apiUrl}/v1/basket/validate`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ANONYMOUS-TOKEN': anonymousToken,
      },
    })

    const { isValid, errors } = await res.json()

    return {
      isValid: isValid,
      errors: Object.keys(errors) as BASKET_ERRORS[],
    }
  } catch (error) {
    console.error('Error while validating basket', error)
  }
}
