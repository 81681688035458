import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import s from './BasketErrorModal.module.css'
import { BASKET_ERRORS } from '@constants'

export interface BasketErrorModalPros {
  errors?: Array<BASKET_ERRORS>
}

const BasketErrorModal: FC<BasketErrorModalPros> = ({
  errors = [BASKET_ERRORS.DEFAULT],
}) => {
  const { t } = useTranslation()

  return (
    <div className={s.container}>
      <h2 className={s.title}>{t(`basket:errorModal.title`)}</h2>
      {errors.map((e) => (
        <p className={s.detail}>{t(`basket:errorModal.${e}`)}</p>
      ))}
    </div>
  )
}

export default BasketErrorModal
