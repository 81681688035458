import { FC } from 'react'
import dynamic from 'next/dynamic'
import { useUI } from '@components/ui/context'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { Footer, Header, Navbar } from '@components/common'
import { LoadingDots, Modal, ScrollToTop, Separator } from '@components/ui'

import { MODALS } from '@constants'

import cn from 'classnames'
import s from './Layout.module.css'
import BasketErrorModal from '@components/basket/ErrorModal/BasketErrorModal'
import { useRouter } from 'next/router'

const Loading = () => (
  <div className="w-80 h-80 m-auto flex items-center text-center justify-center p-3">
    <LoadingDots />
  </div>
)

const NewsletterModal = dynamic(
  () => import('@components/common/NewsletterModal'),
  {
    loading: () => <Loading />,
  }
)

const NotificationModal = dynamic(
  () => import('@components/common/NotificationModal'),
  {
    loading: () => <Loading />,
  }
)

const TelesalesModal = dynamic(
  () => import('@components/common/TelesalesModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyEquivalenceModal = dynamic(
  () => import('@components/loyalty/LoyaltyEquivalenceModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyExitModal = dynamic(
  () => import('@components/loyalty/LoyaltyExitModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyResubscribeModal = dynamic(
  () => import('@components/loyalty/LoyaltyResubscribeModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltySubscribeModal = dynamic(
  () => import('@components/loyalty/LoyaltySubscribeModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyAlertModal = dynamic(
  () => import('@components/loyalty/LoyaltyAlertModal'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyCoupons = dynamic(
  () => import('@components/loyalty/LoyaltyCoupons'),
  {
    loading: () => <Loading />,
  }
)

const LoyaltyErrorModal = dynamic(
  () => import('@components/loyalty/LoyaltyErrorModal'),
  {
    loading: () => <Loading />,
  }
)

const AccountModal = dynamic(() => import('@components/common/AccountModal'), {
  loading: () => <Loading />,
})

interface Props {
  pageProps: {
    alternates?: Array<Alternate>
    countries?: Array<Country>
    currencies: Array<Currency>
    locale: string
    navigation?: NavigationItem[]
    topSeparator?: boolean
    page?: Page
    coupons?: LoyaltyCoupon[]
    logoStyle?: string
  }
}

const Layout: FC<Props> = ({ children, pageProps }) => {
  const {
    displayModal,
    closeModal,
    modalView,
    modalViewProps,
    isSiteReverted,
    enableSiteReverted,
  } = useUI()
  const {
    locale,
    query: { revert },
  } = useRouter()
  const screen = useScreen()

  enableSiteReverted(
    typeof revert !== 'undefined'
      ? true
      : typeof window !== 'undefined'
      ? window?.Translatable?.currentLang
      : locale
  )

  const isDesktop = ![Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(
    screen
  )

  return (
    <div className={cn(s.root)} dir={isSiteReverted ? 'rtl' : 'ltr'}>
      <Header
        alternates={pageProps.alternates}
        locale={pageProps.locale}
        countries={pageProps.countries}
        currencies={pageProps.currencies}
        logoStyle={pageProps.logoStyle}
      />
      {pageProps.navigation ? (
        <Navbar
          navigation={pageProps.navigation}
          alternates={pageProps.alternates}
          locale={pageProps.locale}
          currencies={pageProps.currencies}
          countries={pageProps.countries}
        />
      ) : null}
      {pageProps.topSeparator ? <Separator /> : null}
      <main className="fit">{children}</main>
      <Footer content={pageProps.page?.footer} />

      <ScrollToTop />

      <NewsletterModal
        open={displayModal && modalView === MODALS.NEWSLETTER}
        closeModal={closeModal}
        countries={pageProps.countries}
        emailProp={modalViewProps?.email || ''}
        autoDisplay={modalViewProps?.autoDisplay || false}
        isDesktop={isDesktop}
      />

      <Modal
        open={
          displayModal &&
          [
            MODALS.ACCOUNT,
            MODALS.NOTIFICATION,
            MODALS.TELESALES,
            MODALS.EQUIVALENCE_LOYALTY,
            MODALS.EXIT_LOYALTY,
            MODALS.COUPONS_LOYALTY,
            MODALS.ALERT_LOYALTY,
            MODALS.RESUBSCRIBE_LOYALTY,
            MODALS.SUBSCRIBE_LOYALTY,
            MODALS.LOYALTY_ERROR,
            MODALS.BASKET_ERROR,
          ].includes(modalView)
        }
        onClose={closeModal}
        className={cn(
          { [s.no_overflow]: modalView === MODALS.TELESALES },
          {
            [s.no_border]:
              modalView === MODALS.EXIT_LOYALTY ||
              modalView === MODALS.EQUIVALENCE_LOYALTY ||
              modalView === MODALS.COUPONS_LOYALTY ||
              modalView === MODALS.ALERT_LOYALTY ||
              modalView === MODALS.RESUBSCRIBE_LOYALTY ||
              modalView === MODALS.SUBSCRIBE_LOYALTY ||
              modalView === MODALS.LOYALTY_ERROR ||
              modalView === MODALS.BASKET_ERROR,
          }
        )}
      >
        {modalView === MODALS.ACCOUNT && (
          <AccountModal redirectTo={modalViewProps?.redirectTo} />
        )}
        {modalView === MODALS.NOTIFICATION && (
          <NotificationModal message={modalViewProps?.message || ''} />
        )}
        {modalView === MODALS.TELESALES && (
          <TelesalesModal countries={pageProps.countries} />
        )}
        {modalView === MODALS.EQUIVALENCE_LOYALTY && (
          <LoyaltyEquivalenceModal currencies={pageProps.currencies} />
        )}
        {modalView === MODALS.EXIT_LOYALTY && (
          <LoyaltyExitModal exitProgram={modalViewProps?.exitProgram} />
        )}
        {modalView === MODALS.ALERT_LOYALTY && (
          <LoyaltyAlertModal setIsOptin={modalViewProps?.setIsOptin} />
        )}
        {modalView === MODALS.RESUBSCRIBE_LOYALTY && (
          <LoyaltyResubscribeModal
            resubscribeProgram={modalViewProps?.resubscribeProgram}
          />
        )}
        {modalView === MODALS.SUBSCRIBE_LOYALTY && (
          <LoyaltySubscribeModal
            detailText={modalViewProps?.detailText}
            buttonText={modalViewProps?.buttonText}
            buttonTarget={modalViewProps?.buttonTarget}
          />
        )}
        {modalView === MODALS.COUPONS_LOYALTY && (
          <LoyaltyCoupons
            coupons={modalViewProps?.coupons}
            currencies={pageProps.currencies}
          />
        )}
        {modalView === MODALS.LOYALTY_ERROR && <LoyaltyErrorModal />}
        {modalView === MODALS.BASKET_ERROR && (
          <BasketErrorModal errors={modalViewProps?.errors} />
        )}
      </Modal>
    </div>
  )
}

export default Layout
