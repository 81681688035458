import React, { useState, FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import CheckboxItem from '@components/form/CheckboxItem'
import FormBirthday from '@components/form//FormBirthday'
import ChildInfo from '@components/form/FormChildren/ChildInfo/ChildInfo'
import { Gift } from '@components/icons'

import s from './FormChildren.module.css'

interface FormChildrenProps {
  handleFormData?: (any) => void
  isSubmitted?: boolean
}

const FormChildren: FC<FormChildrenProps> = ({
  handleFormData,
  isSubmitted = false,
}) => {
  const { t } = useTranslation()

  const [isChildComming, setIsChildComing] = useState<boolean>(false)
  const [maxChildren, setMaxChildren] = useState<boolean>(false)

  const [bornChildren, setBornChildren] = useState<
    {
      id?: number
      gender?: string
      firstName?: string
      birthDay?: string
      birthMonth?: string
      birthYear?: string
    }[]
  >([])

  const hasChildren = (init: boolean) => {
    if (init) {
      setBornChildren((bornChildren) => [
        ...bornChildren,
        {
          gender: 'girl',
          birthDay: '01',
          birthMonth: '01',
          birthYear: (new Date().getFullYear() - 6).toString(),
        },
      ])
    } else {
      setBornChildren([])
    }
  }

  const handleDataHasChildren = (e, index) => {
    setBornChildren(
      [...bornChildren].map((child, i) => {
        if (i === index) {
          return {
            ...child,
            [e?.name]: e?.data,
          }
        } else return child
      })
    )
  }

  const handleDataChildComing = (e) => {
    handleFormData &&
      handleFormData({
        mandatory: 'optional',
        masterKey: `unBornChild`,
        key: e?.name,
        value: e?.data,
      })
  }

  useEffect(() => {
    if (!handleFormData) return null
    handleFormData({
      mandatory: 'optional',
      key: 'isChildComming',
      value: isChildComming,
    })
  }, [isChildComming])

  useEffect(() => {
    if (
      bornChildren?.length === 5 ||
      (bornChildren?.length === 4 && isChildComming)
    ) {
      setMaxChildren(true)
    } else {
      setMaxChildren(false)
    }
  }, [bornChildren, isChildComming])

  useEffect(() => {
    setBornChildren([])
  }, [])

  useEffect(() => {
    if (!isSubmitted || !handleFormData) return null

    const childrenData = []

    for (let i = 0; i < bornChildren?.length; i++) {
      childrenData.push({
        gender: bornChildren[i].gender,
        firstName: bornChildren[i].firstName,
        birthday: `${bornChildren[i].birthYear}-${bornChildren[i].birthMonth}-${bornChildren[i].birthDay}`,
      })
    }

    handleFormData({
      mandatory: 'optional',
      key: 'childrenInfos',
      value: childrenData,
    })
  }, [isSubmitted])

  return (
    <>
      <fieldset className={s.formContainer}>
        <h2 className={s.title}>
          {t(`layout:header.modalLogin.register.childrenInfoTitle`)}
        </h2>
        <div className={s.info}>
          <div className={s.separator}>|</div>
          <Gift className={s.gift} stroke="#B89336" />
          <p>{t(`layout:header.modalLogin.register.childGift`)}</p>
        </div>
        <div className={s.formContent}>
          <div className={s.inlineInputs}>
            <div className={s.checkBox}>
              <CheckboxItem
                className={s.checkBoxItem}
                id="register-hasChildren-checkbox"
                setSelected={(e) => hasChildren(e)}
                isSelected={!!bornChildren?.length}
                value="test"
                label={t(`layout:header.modalLogin.register.haveChildren`)}
              />
              <CheckboxItem
                className={s.checkBoxItem}
                id="register-isChildComing-checkbox"
                setSelected={setIsChildComing}
                value="test"
                label={t(
                  `layout:header.modalLogin.register.birthIsCommingTitle`
                )}
              />
            </div>
          </div>
        </div>
      </fieldset>
      {isChildComming && (
        <fieldset className={s.formContainer}>
          <h2 className={s.title}>
            {t(`layout:header.modalLogin.register.birthIsCommingTitle`)}
          </h2>

          <div className={s.formContent}>
            <FormBirthday
              required
              name="birthday"
              label={t(`layout:header.modalLogin.register.dueDate`)}
              minYear={new Date().getFullYear()}
              maxYear={new Date().getFullYear() + 1}
              defaultSelected={{ year: new Date().getFullYear() + 1 }}
              setData={(e) => handleDataChildComing(e)}
            />
          </div>
        </fieldset>
      )}
      {bornChildren?.length ? (
        <>
          <ChildInfo
            setDataToForm={handleDataHasChildren}
            setBornChildren={setBornChildren}
            bornChildren={bornChildren}
            maxChildren={maxChildren}
          />
        </>
      ) : null}
    </>
  )
}

export default FormChildren
