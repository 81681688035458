import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { FormBirthday, FormRadioInput, TextInput } from '@components/form'
import { Separator } from '@components/ui'

import s from './ChildInfo.module.css'

interface ChildInfoProps {
  maxChildren?: boolean
  bornChildren?: any
  setBornChildren?: (any) => void
  setDataToForm?: (...args: any) => void
}

const ChildInfo: FC<ChildInfoProps> = ({
  maxChildren,
  bornChildren,
  setBornChildren,
  setDataToForm,
}) => {
  const { t } = useTranslation()

  const genders = [
    {
      value: 'girl',
      option: t(`layout:header.modalLogin.register.girl`),
    },
    {
      value: 'boy',
      option: t(`layout:header.modalLogin.register.boy`),
    },
  ]

  const handleAddChild = (e: any) => {
    if (!maxChildren) {
      setBornChildren((bornChildren) => [
        ...bornChildren,
        {
          gender: 'girl',
          birthDay: '01',
          birthMonth: '01',
          birthYear: (new Date().getFullYear() - 6).toString(),
        },
      ])
    }
  }

  const handleRemoveChild = (e: any, index) => {
    e.preventDefault()
    let bornChildrenCopy = [...bornChildren]
    bornChildrenCopy?.splice(index, 1)
    setBornChildren(bornChildrenCopy)
  }

  return (
    <>
      {bornChildren?.map((child, index) => (
        <>
          {index > 0 && <Separator className={s.separator} />}
          <fieldset key={`childInfoInput-${index}`} className={s.formContainer}>
            <h2 className={s.title}>
              {t(`layout:header.modalLogin.register.child${index + 1}`)}
            </h2>

            <div className={s.formContent}>
              <FormRadioInput
                name={`children-genders-${index}`}
                options={genders}
                className={s.radioInput}
                label={t(`layout:header.modalLogin.register.childGender`)}
                defaultSelected={child?.gender || 'girl'}
                setData={(e) => setDataToForm(e, index)}
              />
              <TextInput
                id={`firstName`}
                name={`firstName`}
                label={t(`layout:header.modalLogin.register.firstname`)}
                setData={(e) => setDataToForm(e, index)}
              />
              <FormBirthday
                name={`birth`}
                label={t(`layout:header.modalLogin.register.userBirthday`)}
                minYear={new Date().getFullYear() - 18}
                maxYear={new Date().getFullYear()}
                defaultSelected={{
                  day: child?.birthDay || 1,
                  month: child?.birthMonth || 1,
                  year: child?.birthYear || new Date().getFullYear() - 6,
                }}
                setData={(e) => setDataToForm(e, index)}
              />
            </div>
            <div className={s.incrementLine}>
              <button
                className={s.incrementButton}
                onClick={(e) => handleRemoveChild(e, index)}
              >
                {'-' + ' ' + t(`layout:header.modalLogin.register.rmChild`)}
              </button>

              {index === bornChildren?.length - 1 && !maxChildren && (
                <button
                  className={s.incrementButton}
                  onClick={(e) => handleAddChild(e)}
                >
                  {'+' + ' ' + t(`layout:header.modalLogin.register.addChild`)}
                </button>
              )}
            </div>
          </fieldset>
        </>
      ))}
    </>
  )
}

export default ChildInfo
