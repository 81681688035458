import jwt_decode from 'jwt-decode'

const ONE_WEEK = 604800

export const decodeToken: any = (token: string) => {
  if (!token?.length) return null
  return jwt_decode(token)
}

export const toRefresh = ({
  tokenData,
  tokensToRefresh,
}: {
  tokenData: JWT
  tokensToRefresh: string[]
}) => {
  if (!tokenData) return null
  return Object.entries(tokenData).filter(([name, value]) => {
    if (!tokensToRefresh.includes(name)) return null
    const token = decodeToken(value) as TokenContent
    if (token) return Date.now() / 1000 >= token.exp - ONE_WEEK
  })
}
