import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'

interface TagPageCategoryProps {
  pageCategory?: string
  levelOne?: string
  section?: string
  person?: string
  sex?: string
  type?: string
}

const TagPageCategory: FC<TagPageCategoryProps> = ({
  pageCategory = null,
  levelOne = null,
  section = null,
  person = null,
  sex = null,
  type = null,
}) => {
  const GTM_ACCOUNT = process.env.NEXT_PUBLIC_GTM_ACCOUNT || ''
  const { asPath } = useRouter() || { asPath: null, locale: null }

  useEffect(() => {
    if (window?.dataLayer && GTM_ACCOUNT?.length) {
      const _pageCategory = pageCategory
        ? pageCategory
        : asPath === '/'
        ? 'accueil'
        : 'catalogue'

      const tag = Object.assign(
        {},
        { page: _pageCategory },
        ((!levelOne && section) || person) && {
          niveau1: `${section || ''}${section && person ? '-' : ''}${
            person || ''
          }`,
        },
        levelOne && { niveau1: levelOne },
        sex && { niveau2: sex },
        type && { niveau3: type }
      )

      window.dataLayer.push(tag)
    }
  }, [asPath])

  return null
}

export default TagPageCategory
