import React, { useState, useEffect } from 'react'

import { CrossedOutEye, Eye } from '@components/icons'
import Alert, { Status } from '@components/common/Alert/Alert'

import cn from 'classnames'
import s from './DynamicInput.module.css'

export interface DynamicInputProps {
  id: string
  ref?: React.RefObject<HTMLInputElement>
  className?: string
  inputName: string
  value?: string
  type: string
  autoComplete?: string
  required?: boolean
  label: string
  ariaLabel: string
  errorState?: {
    status: Status
    message: string
  }
  isDarktheme?: boolean
  onChange: (...args: any[]) => any
  onBlur?: (...args: any[]) => any
}

const DynamicInput: React.FC<DynamicInputProps> = ({
  id,
  ref,
  className,
  inputName,
  value = '',
  type = 'text',
  autoComplete = 'off',
  required = false,
  label,
  ariaLabel,
  errorState,
  isDarktheme = false,
  onChange,
  onBlur,
}) => {
  const [isUp, setIsUp] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [inputType, setInputType] = useState('')

  const [dataInput, setDataInput] = useState('')

  const handleOnChange = (e: any) => {
    setDataInput(e.target.value)
    onChange(e)
  }
  useEffect(() => {
    setDataInput(value)
  }, [value])
  useEffect(() => {
    setIsUp(!!dataInput?.length)
  }, [dataInput])

  useEffect(() => {
    if (type === 'password') {
      setInputType(() => (isPasswordVisible ? 'text' : 'password'))
    } else {
      setInputType(type)
    }
  }, [isPasswordVisible])

  if (!inputName.length) return null

  return (
    <>
      <div className={cn(s.formItem)}>
        {type === 'password' && (
          <div
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            className={s.eye}
          >
            {isPasswordVisible ? <CrossedOutEye /> : <Eye />}{' '}
          </div>
        )}

        <input
          id={id}
          ref={ref}
          className={cn(s.formInput, className, {
            [s.isPassword]: type === 'password',
            [s.darkTheme]: isDarktheme,
          })}
          name={inputName}
          type={inputType}
          required={required}
          value={dataInput}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          onChange={handleOnChange}
          onBlur={onBlur}
          data-cs-mask
        />
        {label.length ? (
          <label
            htmlFor={id}
            className={cn(s.formLabel, {
              [s.formUp]: isUp,
              [s.darkTheme]: isDarktheme,
            })}
          >
            {label}
          </label>
        ) : null}
      </div>
      {errorState && errorState.status && errorState.message && (
        <Alert
          status={errorState.status}
          message={errorState.message}
          className={s.alertMessage}
        />
      )}
    </>
  )
}

export default DynamicInput
