import { FC } from 'react'

import { TagPageCategory } from '@components/common/GoogleTagManager'
import NotFound from '../NotFound/NotFound'
import Head from './Head'
import { TagGeneric } from '../Eulerian'

const HeadError: FC = () => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <TagGeneric isError />
      <TagPageCategory pageCategory="erreur" />
      <NotFound />
    </>
  )
}

export default HeadError
