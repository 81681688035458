import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'

interface TagPageConfirmProps {
  orderData: OrderTagGoogle
}

const TagPageConfirm: FC<TagPageConfirmProps> = ({ orderData }) => {
  const GTM_ACCOUNT = process.env.NEXT_PUBLIC_GTM_ACCOUNT || ''
  const { asPath } = useRouter() || { asPath: null, locale: null }

  useEffect(() => {
    if (window?.dataLayer && GTM_ACCOUNT?.length) {
      window.dataLayer.push({
        page: 'tunnel',
        niveau1: 'confirmation',
        niveau2: '',
        niveau3: '',
      })
      if (orderData) {
        const value =
          parseFloat(orderData.ecommerce?.purchase?.actionField?.revenueHTC) ||
          null
        const currency = orderData.ecommerce?.currencyCode || null
        const orderDataWithConversion = {
          ...orderData,
          ...(value &&
            currency && {
              params: {
                value,
                currency,
              },
            }),
        }
        window.dataLayer.push(orderDataWithConversion)
      }
    }
  }, [asPath])

  return null
}

export default TagPageConfirm
