import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import cn from 'classnames'
import s from './Copyright.module.css'

export interface CopyrightProps {
  className?: string
}

const Copyright: FC<CopyrightProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={cn(s.container, className)}>
      <ul className={s.nav}>
        <li>
          <a href={`${t('layout:footer.about.legalNotice.href')}`}>
            {t('layout:footer.about.legalNotice.title')}
          </a>
        </li>
        <li>
          <a href={`${t('layout:footer.about.salesConditions.href')}`}>
            {t('layout:footer.about.salesConditions.title')}
          </a>
        </li>
        <li>
          <button
            onClick={() => {
              if (typeof window.Didomi.preferences.show !== 'undefined')
                window.Didomi.preferences.show()
            }}
          >
            {t('layout:footer.about.cookies.title')}
          </button>
        </li>
      </ul>
      <p className={s.copyright}>
        {t('layout:footer.copyright', { year: new Date().getFullYear() })}
      </p>
    </div>
  )
}

export default Copyright
