import React, { FC } from 'react'

const SalerCreditCard: FC<any> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.1818 5H3.81818C2.81403 5 2 5.7835 2 6.75V17.25C2 18.2165 2.81403 19 3.81818 19H20.1818C21.186 19 22 18.2165 22 17.25V6.75C22 5.7835 21.186 5 20.1818 5Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V10.25H2V17Z"
      fill="#555555"
    />
    <path
      d="M2 7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V10.25H2V7Z"
      fill="#555555"
    />
    <path
      d="M2 10.25H22"
      stroke="white"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <rect
      x="3.81812"
      y="14.625"
      width="5.45455"
      height="2.625"
      rx="1"
      fill="white"
    />
  </svg>
)

export default SalerCreditCard
