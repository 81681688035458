import React, { FC, useEffect, useState } from 'react'
import { useUserSettings } from '@context/session'
import { useRouter } from 'next/router'

import {
  setLocalShop,
  setLocalPrinter,
  setLocalPose,
  getShop,
  getPrinter,
  getPose,
} from '@utils/salerModal'

import {
  SalerPig,
  SalerHome,
  SalerPrinter,
  SalerCreditCard,
  SalerBook,
} from '@components/icons'

import { fetchStores, updateCurrentItems } from '@mw/telesale'

import cn from 'classnames'
import s from './SalerModal.module.css'

interface SalerSettingsSelected {
  shop: Store
  pose: Pose
  printer: Printer
  stock: string
}

const SalerModal: FC<{}> = () => {
  const { tokens, refreshSession, shopConfig } = useUserSettings()
  const { locale } = useRouter()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [stores, setStores] = useState<Array<Store>>(null)
  const [salerSettingsSelected, setSalerSettingsSelected] =
    useState<SalerSettingsSelected>({
      shop: getShop(),
      pose: getPose(),
      printer: getPrinter(),
      stock: null,
    })

  const token = tokens?.['X-AUTH-TOKEN'] || null

  const setShop = ({
    shop,
    printer = null,
    pose = null,
    stock = null,
  }: {
    shop: Store
    printer?: Printer
    pose?: Pose
    stock?: string
  }) => {
    const newPrinter = printer || getPrinter()
    const newPose = pose || getPose()
    const newStock = stock ? shopConfig?.stock : '-'
    setSalerSettingsSelected({
      shop,
      pose: newPose,
      printer: newPrinter,
      stock: newStock,
    })
    setLocalShop(JSON.stringify(shop))
  }

  const onChangePose = async (e) => {
    const poseId = parseInt(e.target.value)
    const pose = salerSettingsSelected.shop.poses.find(
      (pose) => pose.id === poseId
    )
    setSalerSettingsSelected((prevState) => ({ ...prevState, pose }))
    setLocalPose(pose)
    try {
      const changedPos = await updateCurrentItems({
        item: 'pos',
        id: poseId,
        locale: locale,
      })
      if (changedPos === 'ok') refreshSession()
      else throw Error
    } catch (error) {
      console.error(error)
    }
  }

  const onChangePrinter = async (e) => {
    const printerId = parseInt(e.target.value)
    const printer = salerSettingsSelected.shop.printers.find(
      (printer) => printer.id === printerId
    )
    setSalerSettingsSelected((prevState) => ({ ...prevState, printer }))
    setLocalPrinter(printer)
    try {
      const changedPrinter = await updateCurrentItems({
        item: 'printer',
        id: printerId,
        locale: locale,
      })
      if (changedPrinter === 'ok') refreshSession()
      else throw Error
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeShop = (e) => {
    const shopId = parseInt(e.target.value)
    const shop = stores.find((shop) => shop.id === shopId)
    if (shop) setShop({ shop })
  }

  const onChangeStock = async (e) => {
    const stock = e.target.value
    setSalerSettingsSelected((prevState) => ({ ...prevState, stock }))
    try {
      const changedStock = await updateCurrentItems({
        item: 'stock',
        id: stock,
        locale: locale,
      })
      if (changedStock === 'ok') refreshSession()
      else throw Error
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        const salerSettings = await fetchStores({ authToken: token })
        setStores(salerSettings)
        setShop({ shop: salerSettings[0] || null })
      } catch (error) {
        console.error(error)
      }
    })()
  }, [])

  return (
    <div className={cn(s.container, { [s.close]: !isOpen })}>
      <button className={s.button} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <span>{'>'}</span> : <SalerPig width={18} />}
      </button>
      {stores ? (
        <form className={s.form}>
          <ul>
            {stores?.length ? (
              <li>
                <span>
                  <SalerHome width={15} />
                </span>
                <select
                  name="stores"
                  value={salerSettingsSelected?.shop?.id}
                  onChange={onChangeShop}
                >
                  {stores.map((shop) => (
                    <option value={shop.id} key={`shop-id-${shop.id}`}>
                      {shop.name}
                    </option>
                  ))}
                </select>
              </li>
            ) : null}
            {salerSettingsSelected?.shop?.printers?.length ? (
              <li>
                <span>
                  <SalerPrinter width={15} />
                </span>
                <select
                  name="printers"
                  value={salerSettingsSelected?.printer?.id || ''}
                  onChange={(e) => onChangePrinter(e)}
                >
                  <option value={''}>-</option>
                  {salerSettingsSelected.shop.printers.map((printer) => (
                    <option value={printer.id} key={`printer-id-${printer.id}`}>
                      {printer.name}
                    </option>
                  ))}
                </select>
              </li>
            ) : null}
            {salerSettingsSelected?.shop?.poses?.length ? (
              <li>
                <span>
                  <SalerCreditCard width={15} />
                </span>
                <select
                  name="poses"
                  value={salerSettingsSelected?.pose?.id || ''}
                  onChange={(e) => onChangePose(e)}
                >
                  <option value={''}>-</option>
                  {salerSettingsSelected.shop.poses.map((pose) => (
                    <option value={pose.id} key={`pose-id-${pose.id}`}>
                      {pose.name}
                    </option>
                  ))}
                </select>
              </li>
            ) : null}
            {shopConfig?.stock?.length ? (
              <li>
                <span>
                  <SalerBook width={15} />
                </span>
                <select
                  name="stock"
                  value={shopConfig?.stock}
                  onChange={(e) => onChangeStock(e)}
                >
                  <option value={''}>-</option>
                  <option value="private_stock" key={'private'}>
                    Stock Privé
                  </option>
                  <option value="public_stock" key={`public`}>
                    Stock Public
                  </option>
                  ))
                </select>
              </li>
            ) : null}
          </ul>
        </form>
      ) : (
        <p className={s.error}>Une erreur s'est produite</p>
      )}
    </div>
  )
}

export default SalerModal
