import { FC, useRef, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Error, FormErrors } from '@components/form'
import { Status } from '@components/common/Alert/Alert'
import { tagEventCS } from '@components/common/ContentSquare'
import { CrossedOutEye, Eye } from '@components/icons'
import PasswordChecklist from '@components/form/PasswordChecklist'
import { PasswordRuleStatus } from '@components/form/PasswordChecklist/PasswordChecklist'
import { passwordRules } from '@components/form/FormModel'

import { PASSWORD_REGEXP } from '@constants'
import { getCookie } from '@utils/cookies'

import cn from 'classnames'
import s from './PasswordInput.module.css'

interface PasswordInputProps {
  id: string
  name: string
  complementaryLabel?: string
  required?: boolean
  errors?: any
  setErrors?: any
  handleFormData?: (any) => void
}

const PasswordInput: FC<PasswordInputProps> = ({
  id,
  name,
  complementaryLabel = null,
  required = false,
  errors,
  setErrors,
  handleFormData,
  ...restProps
}) => {
  const { t } = useTranslation()
  const errorsObject = FormErrors()

  const valueInput = useRef<string>('')
  const [rulesState, setRulesState] = useState(passwordRules)
  const [isRender, setIsRender] = useState<boolean>(true)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [isUp, setIsUp] = useState<boolean>(false)
  const [inputType, setInputType] = useState('')

  const [dataInput, setDataInput] = useState<{
    data: string
    name: string
  }>({ data: '', name: '' })

  const label =
    t('layout:header.modalLogin.input.password') +
    (required ? '*' : '') +
    (complementaryLabel ? ` (${complementaryLabel})` : '')

  const displayPasswordRules = (e) => {
    setDataInput({
      ...dataInput,
      data: e?.target.value,
      name: e?.target.name,
    })

    const rulesCheckResult = passwordRules.map((rule) => {
      if (e?.target?.value.match(rule.value))
        return { ...rule, ['status']: PasswordRuleStatus.FormSuccess }
      else return { ...rule, ['status']: PasswordRuleStatus.FormInfo }
    })

    required && setRulesState(rulesCheckResult)
  }

  const handleOnFocus = () => {
    required &&
      setErrors({
        ...errors,
        [name]: 'ok',
      })
  }

  const handleOnBlur = () => {
    required &&
      setErrors({
        ...errors,
        [name]: null,
      })
    if (!dataInput?.data.match(PASSWORD_REGEXP)) {
      required &&
        setErrors({
          ...errors,
          [name]: errorsObject?.password,
        })

      const rulesCheckResult = passwordRules.map((rule) => {
        if (dataInput?.data.match(rule.value))
          return { ...rule, ['status']: PasswordRuleStatus.FormSuccess }
        else return { ...rule, ['status']: PasswordRuleStatus.FormError }
      })

      required && setRulesState(rulesCheckResult)
      return null
    }

    handleFormData &&
      handleFormData({
        mandatory: required ? 'mandatory' : 'optional',
        key: dataInput?.name,
        value: dataInput?.data,
      })

    required &&
      setErrors({
        ...errors,
        [name]: 'ok',
      })
  }

  const tagEvent = () => {
    if (dataInput?.data.length > 0 && !dataInput?.data.match(PASSWORD_REGEXP)) {
      tagEventCS({
        eventName: 'password_error',
        eventType: 'trackPageEvent',
      })
      return null
    }
  }

  useEffect(() => {
    if (errors[name]?.type === Error.EmptyField) {
      const ruleState = passwordRules.map((rule) => {
        return { ...rule, ['status']: PasswordRuleStatus.FormError }
      })

      setRulesState(ruleState)
    }
  }, [errors])

  useEffect(() => {
    const fbCookie = getCookie('SML_FB_CONNECT', document?.cookie)
    if (!fbCookie) return null
    setIsRender(false)
  }, [])

  useEffect(() => {
    setInputType(() => (isPasswordVisible ? 'text' : 'password'))
  }, [isPasswordVisible])

  if (!isRender) return null

  return (
    <div className={s.container} {...restProps}>
      <div className={s.formItem}>
        <div
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          className={s.eye}
        >
          {isPasswordVisible ? <CrossedOutEye /> : <Eye />}{' '}
        </div>
        <input
          id={id}
          className={cn(
            s.formInput,
            s.isPassword,
            cn({
              [s.errorInput]:
                errors[name]?.type === Error.EmptyField ||
                (errors[name]?.type === Error.PasswordRules &&
                  errors[name]?.status !== Status.FormInfo),
            })
          )}
          name={name}
          type={inputType}
          value={valueInput.current}
          aria-label={t('layout:header.modalLogin.input.password')}
          required={required}
          maxLength={40}
          autoComplete="off"
          onChange={(e) => {
            valueInput.current = e.target.value
            displayPasswordRules(e)
          }}
          onBlur={(e) => {
            setIsUp(!!valueInput.current.length)
            handleOnBlur()
            tagEvent()
          }}
          onFocus={(e) => {
            setIsUp(!!valueInput.current.length)
            displayPasswordRules(e)
            handleOnFocus()
          }}
          data-cs-mask
        />
        {label?.length ? (
          <label htmlFor={id} className={cn(s.formLabel, { [s.formUp]: isUp })}>
            {label}
          </label>
        ) : null}
      </div>
      <PasswordChecklist passwordRules={rulesState} />
    </div>
  )
}

export default PasswordInput
