import { FC, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Arrow } from '@components/ui'

import { sortCountriesAlphabetically } from '@utils/countries'

import cn from 'classnames'
import s from './FormLocationSelector.module.css'

export interface FormlocationSelectorProps {
  className?: any
  label?: string
  name?: string
  localizationItems?: Country[] | Region[]
  required?: boolean
  setData?: (...args: any) => void
  defaultValue?: number
}

const FormLocationSelector: FC<FormlocationSelectorProps> = ({
  className,
  name,
  localizationItems,
  label,
  required = false,
  setData,
  defaultValue,
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    setData({
      mandatory: required ? 'mandatory' : 'optional',
      name: name,
      data: value,
    })
  }, [value])

  return (
    <div className={cn(s.container, className)}>
      <div className={s.formItem}>
        <Arrow
          width="12"
          height="12"
          stroke="grey"
          className={s.arrowDown}
          aria-hidden
        />
        <select
          className={s.select}
          onChange={(e) => setValue(Number(e?.target?.value))}
          aria-label={t('layout:header.country.ariaLabel')}
          value={value}
        >
          {sortCountriesAlphabetically(localizationItems)?.map((country) => (
            <option value={country.id} key={country.id}>
              {country.name}
            </option>
          ))}
        </select>
        {label?.length ? <label className={s.formLabel}>{label}</label> : null}
      </div>
    </div>
  )
}

export default FormLocationSelector
