import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'

import { clearWindowForPartners, collector } from '@lib/eulerian'

interface TagGenericProps {
  isError?: boolean
  urlp?: string
  orderData?: Order
}

const TagGeneric: FC<TagGenericProps> = ({
  isError,
  urlp,
  orderData = null,
}) => {
  const isTranslatableLoaded =
    typeof window !== 'undefined' && window.Translatable?.initialized

  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = isTranslatableLoaded
    ? window.Translatable.currentLang
    : process.env.NEXT_PUBLIC_APP_VERSION || ''

  const { asPath, locale } = useRouter() || { asPath: null, locale: null }
  const { isEALoaded } = useUI()
  const {
    isFetched,
    emailMd5,
    emailSha256,
    uid,
    clientType,
    currencyCode,
    logged,
    subscribedToLoyalty,
    deliveryCountryCode,
    basket,
  } = useUserSettings()

  const _urlp = locale && urlp ? locale + urlp : null

  const genericTags =
    !asPath || !locale
      ? null
      : [
          ...(isError ? ['urlp', '/error'] : _urlp ? ['urlp', _urlp] : []),
          ...(emailSha256 ? ['email', emailSha256] : []),
          ...(emailMd5 ? ['email_md5', emailMd5] : []),
          ...(locale ? ['lang', locale] : []),
          ...(uid ? ['uid', uid] : []),
          ...(currencyCode ? ['currency', currencyCode] : []),
          ...(currencyCode ? ['devise', currencyCode] : []),
          ...(clientType ? ['type_client', clientType] : []),
          ...['is_logged', `${logged ? 'true' : 'false'}`],
          ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
          ...(appEnv ? ['env', appEnv] : []),
          ...(appVersion ? ['version', appVersion] : []),
        ]

  if (orderData) {
    const orderTags = [
      ...(orderData?.totalAmount
        ? ['order_total_amount', orderData.totalAmount]
        : []),
      ...(orderData?.id ? ['order_id', orderData.id.toString()] : []),
    ]
    genericTags.push(...orderTags)
  }

  useEffect(() => {
    if (isFetched && isEALoaded && genericTags?.length) {
      clearWindowForPartners(genericTags)
      collector({
        tags: window.data_layer,
        urlp: _urlp,
        collectType: 'print',
        deliveryCountryCode,
        basket,
      })
    }
  }, [asPath, isFetched, isEALoaded])

  return null
}

export default TagGeneric
