import React, { FC, useState, useEffect } from 'react'
import { DynamicInput, Error, FormErrors } from '@components/form'
import { getCookie } from '@utils/cookies'
import cn from 'classnames'
import s from './TextInput.module.css'

interface TextInputProps {
  id: string
  name: string
  label?: string
  value?: string
  complementaryLabel?: string
  required?: boolean
  maxLength?: number
  optional?: boolean
  setData: (data: any) => void
  selectedValue?: string
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  errors?: any
  setErrors?: any
}

const TextInput: FC<TextInputProps> = ({
  id,
  name,
  label,
  value = '',
  complementaryLabel = '',
  required = false,
  maxLength,
  optional = false,
  setData,
  selectedValue = '',
  onInputChange,
  errors,
  setErrors,
}) => {
  const errorsObject = FormErrors()
  const [dataInput, setDataInput] = useState<{
    data: string
    name: string
  }>({
    data: '',
    name: '',
  })

  useEffect(() => {
    setDataInput({ data: selectedValue || '', name })
  }, [selectedValue])

  const handleOnBlur = () => {
    if (
      required &&
      (dataInput.data === undefined || dataInput.data.length === 0)
    ) {
      setErrors({
        ...errors,
        [name]: errorsObject?.emptyField,
      })
      return
    }

    setData({
      mandatory: required ? 'mandatory' : 'optional',
      data: dataInput.data,
      name: dataInput.name,
    })
    if (required) {
      setErrors({
        ...errors,
        [name]: 'ok',
      })
    }
  }

  useEffect(() => {
    if (required) {
      setErrors({
        ...errors,
        [name]: null,
      })
    }
    handleOnBlur()
  }, [dataInput.data])

  useEffect(() => {
    if (name !== 'firstName' && name !== 'lastName') return
    const fbCookie = getCookie('SML_FB_CONNECT', document?.cookie)
    if (!fbCookie) return

    const fbInfos = JSON.parse(fbCookie)
    if (name === 'firstName') {
      setDataInput({
        ...dataInput,
        data: fbInfos?.first_name || '',
        name: 'firstName',
      })
    }

    if (name === 'lastName') {
      setDataInput({
        ...dataInput,
        data: fbInfos?.last_name || '',
        name: 'lastName',
      })
    }
  }, [])

  useEffect(() => {
    setDataInput({ data: value || '', name: id })
  }, [value, id])

  return (
    <DynamicInput
      id={id}
      type="text"
      inputName={name}
      label={`${label || ''}${required ? '*' : ''} ${
        complementaryLabel ? `(${complementaryLabel})` : ''
      }`}
      className={cn({
        [s.errorInput]: required && errors[name]?.type === Error.EmptyField,
      })}
      optional={optional}
      maxLength={maxLength}
      ariaLabel={label}
      value={dataInput.data}
      onChange={(e) => {
        setDataInput({
          data: e.target.value,
          name: e.target.name,
        })
        if (onInputChange) {
          onInputChange(e)
        }
      }}
      onBlur={handleOnBlur}
      errorState={required && errors[name]}
    />
  )
}

export default TextInput
