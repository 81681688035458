import { gql } from '@apollo/client'
import client from '@lib/apolloClient'

export const fetchBannerConfig = async (
  locale: string,
  countryCode: string,
  headers?: Headers
): Promise<BannerConfig> => {
  const { data, errors } = await client.query<{ banner: BannerConfig }>({
    query: gql`
      query Banner($locale: Locale, $countryCode: CountryCode) {
        banner(locale: $locale, countryCode: $countryCode) {
          backgroundColor
          textColor
          banners {
            endText
            href
            linkText
            startText
            target
          }
        }
      }
    `,
    variables: {
      countryCode: countryCode?.toUpperCase(),
      locale,
    },
    context: {
      headers: {
        ...headers,
      },
    },
  })

  if (errors?.length || !data?.banner)
    throw {
      status: 400,
      message: errors?.[0]?.message || 'Failed to fetch banner config',
    }

  return data.banner
}
