import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Cross, Check } from '@components/icons'

import cn from 'classnames'
import s from './PasswordChecklist.module.css'

export enum PasswordRuleStatus {
  FormError = 'formError',
  FormInfo = 'formInfo',
  FormSuccess = 'formSuccess',
}

export interface PasswordRule {
  name: string
  value: RegExp
  status: PasswordRuleStatus
  text: string
}

export interface PasswordRulesChecklistInput {
  passwordRules: PasswordRule[]
}

const PasswordChecklist: FC<PasswordRulesChecklistInput> = ({
  passwordRules,
}) => {
  const { t } = useTranslation()

  return (
    <div className={s.PasswordChecklist}>
      {passwordRules?.map((rule, i) => {
        let style
        if (rule.status === PasswordRuleStatus.FormError)
          style = {
            className: s.formError,
            symbol: <Cross className={s.symbolError} />,
          }
        else if (rule.status === PasswordRuleStatus.FormSuccess)
          style = {
            className: s.formSuccess,
            symbol: <Check className={s.symbolSuccess} stroke="#23a942" />,
          }
        else
          style = {
            className: s.formInfo,
            symbol: <li className={s.symbolInfo} />,
          }

        return (
          <div
            key={`pwd_helper_item_${rule.status}_${i}`}
            className={cn(style.className)}
          >
            {style.symbol} {t(rule.text)}
          </div>
        )
      })}
    </div>
  )
}

export default PasswordChecklist
