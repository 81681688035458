import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Selector } from '@components/form'

import date from './date.json'

import s from './FormBirthday.module.css'

interface FormBirthdayProps {
  label?: string
  name: string
  required?: boolean
  minYear?: number
  maxYear?: number
  defaultSelected?: {
    day?: number
    month?: number
    year?: number
  }
  setData?: (any) => void
}

const FormBirthday: FC<FormBirthdayProps> = ({
  label,
  name,
  required = false,
  minYear = 1930,
  maxYear = new Date().getFullYear() - 16,
  defaultSelected = { day: 1, month: 1, year: new Date().getFullYear() - 40 },
  setData,
}) => {
  const { t } = useTranslation()

  const [selectedMonth, setSelectedMonth] = useState<number>(
    defaultSelected?.month
  )
  const [selectedYear, setSelectedYear] = useState<number>(
    defaultSelected?.year
  )
  const [days, setDays] = useState([])

  const formattedData = (value) => (Number(value) < 10 ? `0${value}` : value)

  const isBissextile = (year: number) => {
    if (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0)) return true
    else return false
  }

  const months = date?.month?.map((month: string, i) => {
    return { option: t(`layout:${month}`), value: i + 1 }
  })

  const years = []
  for (let i = minYear; i <= maxYear; i++) {
    years.push({ option: i, value: i })
  }

  useEffect(() => {
    const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12]
    if (monthsWith31Days.includes(Number(selectedMonth))) {
      setDays([])
      for (let i = 1; i <= 31; i++) {
        setDays((days) => [...days, { option: i, value: i }])
      }
      return null
    } else {
      setDays([])
      if (Number(selectedMonth) === 2) {
        if (!isBissextile(selectedYear)) {
          for (let i = 1; i <= 28; i++) {
            setDays((days) => [...days, { option: i, value: i }])
          }
        } else {
          for (let i = 1; i <= 29; i++) {
            setDays((days) => [...days, { option: i, value: i }])
          }
        }
        return null
      } else {
        for (let i = 1; i <= 30; i++) {
          setDays((days) => [...days, { option: i, value: i }])
        }
        return null
      }
    }
  }, [selectedMonth, selectedYear])

  return (
    <div className={s.container}>
      <h3 className={s.title}>{`${label}${required ? '*' : ''}`} </h3>
      <div className={s.selectors}>
        <div className={s.left}>
          <Selector
            className={s.small}
            options={days}
            defaultSelected={defaultSelected?.day}
            label={t(`layout:header.modalLogin.register.day`)}
            onChange={(e) => {
              setData({
                data: formattedData(e.target.value),
                name: `${name}Day`,
              })
            }}
          />
          <Selector
            className={s.long}
            options={months}
            defaultSelected={defaultSelected?.month}
            label={t(`layout:header.modalLogin.register.month`)}
            onChange={(e) => {
              setSelectedMonth(e.target.value)
              setData({
                data: formattedData(e.target.value),
                name: `${name}Month`,
              })
            }}
          />
        </div>
        <div className={s.right}>
          <Selector
            className={s.small}
            options={years}
            label={t(`layout:header.modalLogin.register.year`)}
            defaultSelected={defaultSelected?.year}
            onChange={(e) => {
              setSelectedYear(e.target.value)
              setData({
                data: e.target.value,
                name: `${name}Year`,
              })
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FormBirthday
