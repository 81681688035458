import { FC, KeyboardEvent, useState, useEffect } from 'react'

import { Check } from '@components/icons'

import cn from 'classnames'
import s from './CheckboxItem.module.css'

interface CheckboxItemProps {
  className?: string
  id?: string
  value?: string
  label?: string
  isSelected?: boolean
  setSelected: (any) => void
}

const CheckboxItem: FC<CheckboxItemProps> = ({
  className,
  id,
  value,
  isSelected = false,
  label,

  setSelected,
}) => {
  const [isBoxChecked, setIsBoxChecked] = useState(false)

  const onClickHandler = () => {
    setIsBoxChecked(!isBoxChecked)
  }

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClickHandler()
    }
  }

  useEffect(() => {
    setSelected(isBoxChecked)
  }, [isBoxChecked])

  useEffect(() => {
    if (!isSelected) {
      setIsBoxChecked(false)
    }
  }, [isSelected])

  return (
    <div
      className={cn(s.container, className)}
      data-cs-override-id={`checkboxItem-1`}
    >
      <div className={s.content}>
        <input
          type="checkbox"
          id={`checkbox_${label}`}
          className={cn(s.box, { [s.checked]: isBoxChecked })}
          value={value}
          checked={isBoxChecked}
          aria-checked={isBoxChecked}
          onChange={onClickHandler}
          onKeyDown={(e) => onKeyDownHandler(e)}
        />
        <label htmlFor={`checkbox_${label}`} className={s.title}>
          {isBoxChecked && <Check aria-hidden />}
          <span
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
        </label>
      </div>
    </div>
  )
}

export default CheckboxItem
