const Earphone = ({ width = '21', height = '20', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.95898 11.8769V7.79291C4.95898 6.16822 5.58646 4.61007 6.70339 3.46123C7.82031 2.3124 9.33518 1.66699 10.9147 1.66699C12.4943 1.66699 14.0092 2.3124 15.1261 3.46123C16.243 4.61007 16.8705 6.16822 16.8705 7.79291V11.8769"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8711 10.1749L16.8711 12.8975C16.8711 13.9806 16.2436 15.0194 15.1267 15.7853C14.0098 16.5512 12.4949 16.9814 10.9153 16.9814"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.95898 12.4791C4.95898 12.8491 5.10189 13.2039 5.35626 13.4656C5.61063 13.7272 5.95563 13.8742 6.31537 13.8742H6.99356C7.3533 13.8742 7.6983 13.7272 7.95267 13.4656C8.20704 13.2039 8.34994 12.8491 8.34994 12.4791V10.3863C8.34994 10.0163 8.20704 9.66148 7.95267 9.39984C7.6983 9.1382 7.3533 8.99121 6.99356 8.99121H4.95898V12.4791Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4605 13.4656C16.7148 13.2039 16.8578 12.8491 16.8578 12.4791V8.99121H14.8232C14.4634 8.99121 14.1184 9.1382 13.8641 9.39984C13.6097 9.66148 13.4668 10.0163 13.4668 10.3863V12.4791C13.4668 12.8491 13.6097 13.2039 13.8641 13.4656C14.1184 13.7272 14.4634 13.8742 14.8232 13.8742H15.5014C15.8611 13.8742 16.2061 13.7272 16.4605 13.4656Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10.8406" cy="16.9079" r="1.12766" stroke="#121212" />
  </svg>
)

export default Earphone
