import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'

import { collector, clearWindowForPartners } from '@lib/eulerian'

interface TagOrderProps {
  urlp: string
  orderData: OrderTag
}

const TagConfirm: FC<TagOrderProps> = ({ urlp, orderData }) => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV || ''
  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || ''
  const { asPath, locale } = useRouter() || { asPath: null, locale: null }

  const {
    isFetched,
    email,
    emailMd5,
    emailSha256,
    uid,
    clientType,
    logged,
    subscribedToLoyalty,
  } = useUserSettings()
  const { isEALoaded } = useUI()

  const orderDataPrd = orderData?.['0']?.[0] || null

  const _urlp = locale && urlp ? locale + urlp : null

  const orderTags =
    !orderData || !orderDataPrd || !asPath || !locale
      ? null
      : [
          ...(_urlp ? ['urlp', _urlp] : []),
          ...(emailSha256 ? ['email', emailSha256] : []),
          ...(emailMd5 ? ['email_md5', emailMd5] : []),
          ...(email ? ['email_clear', email] : []),
          ...(locale ? ['lang', locale] : []),
          ...(uid ? ['uid', uid] : []),
          ...(clientType ? ['type_client', clientType] : []),
          ...(appEnv ? ['env', appEnv] : []),
          ...(appVersion ? ['version', appVersion] : []),
          ...['is_logged', `${logged ? 'true' : 'false'}`],
          ...['loyaltySubscribed', `${subscribedToLoyalty ? 'true' : 'false'}`],
          ...(orderData.currency ? ['currency', orderData.currency] : []),
          ...(orderData.currency ? ['devise', orderData.currency] : []),
          ...(orderData.ref ? ['ref', `${orderData.ref}`] : []),
          ...(orderData.type ? ['type', orderData.type] : []),
          ...(orderData.amount ? ['amount', `${orderData.amount}`] : []),
          ...(orderData.status ? ['status', orderData.status] : []),
          ...(orderData.amount_TTC_avec_fraisport_ttc
            ? [
                'amount_TTC_avec_fraisport_ttc',
                `${orderData.amount_TTC_avec_fraisport_ttc}`,
              ]
            : []),
          ...(orderData.amount_HT_avec_fraisport_ht
            ? [
                'amount_HT_avec_fraisport_ht',
                `${orderData.amount_HT_avec_fraisport_ht}`,
              ]
            : []),
          ...(orderData.amount_HT_hors_fraisport_avantcodepromo
            ? [
                'amount_HT_hors_fraisport_avantcodepromo',
                `${orderData.amount_HT_hors_fraisport_avantcodepromo}`,
              ]
            : []),
          ...(orderData.amount_HT_avec_fraisport_avantcodepromo
            ? [
                'amount_HT_avec_fraisport_avantcodepromo',
                `${orderData.amount_HT_avec_fraisport_avantcodepromo}`,
              ]
            : []),
          ...(orderData.amount_TTC_hors_fraisport
            ? [
                'amount_TTC_hors_fraisport',
                `${orderData.amount_TTC_hors_fraisport}`,
              ]
            : []),
          ...(orderData.frais_de_port
            ? ['frais_de_port', `${orderData.frais_de_port}`]
            : []),
          ...(orderData.codepromo ? ['codepromo', orderData.codepromo] : []),
          ...(orderData.codepromo_valeur
            ? ['codepromo_valeur', orderData.codepromo_valeur]
            : []),
          ...(orderData.codepromo_montant
            ? ['codepromo_montant', orderData.codepromo_montant]
            : []),
          ...(orderData.payment ? ['payment', orderData.payment] : []),
          ...(orderData.profile ? ['profile', orderData.profile] : []),
          ...(orderData.nc ? ['nc', `${orderData.nc}`] : []),
          ...(orderDataPrd?.['prdamount']
            ? ['prdamount', `${orderDataPrd['prdamount']}`]
            : []),
          ...(orderDataPrd?.['prdamount_ttc_avantreduc']
            ? [
                'prdamount_ttc_avantreduc',
                orderDataPrd['prdamount_ttc_avantreduc'],
              ]
            : []),
          ...(orderDataPrd?.['prdgroup']
            ? ['prdgroup', orderDataPrd['prdgroup']]
            : []),
          ...(orderDataPrd?.['prdname']
            ? ['prdname', orderDataPrd['prdname']]
            : []),
          ...(orderDataPrd?.['prdquantity']
            ? ['prdquantity', `${orderDataPrd['prdquantity']}`]
            : []),
          ...(orderDataPrd?.['prdparam-brand']
            ? ['prdparam-brand', orderDataPrd['prdparam-brand']]
            : []),
          ...(orderDataPrd?.['prdparam-stock']
            ? ['prdparam-stock', orderDataPrd['prdparam-stock']]
            : []),
          ...(orderDataPrd?.['prdparam-category']
            ? ['prdparam-category', orderDataPrd['prdparam-category']]
            : []),
          ...(orderDataPrd?.['prdparam-refco']
            ? ['prdparam-refco', orderDataPrd['prdparam-refco']]
            : []),
          ...(orderDataPrd?.['prdparam-refproduit']
            ? ['prdref', orderDataPrd['prdparam-refproduit']]
            : []),
          ...(orderDataPrd?.['prdparam-refproduit']
            ? ['prdparam-refproduit', orderDataPrd['prdparam-refproduit']]
            : []),
          ...(orderDataPrd?.['prdparam-amountHT']
            ? ['prdparam-amountHT', `${orderDataPrd['prdparam-amountHT']}`]
            : []),
          ...(orderDataPrd?.['prdparam-color']
            ? ['prdparam-color', orderDataPrd['prdparam-color']]
            : []),
          ...(orderDataPrd?.['prdparam-size']
            ? ['prdparam-size', orderDataPrd['prdparam-size']]
            : []),
          ...(orderDataPrd?.['prdparam-genre']
            ? ['prdparam-genre', orderDataPrd['prdparam-genre']]
            : []),
          ...(orderDataPrd?.['prdparam-sexe']
            ? ['prdparam-sexe', orderDataPrd['prdparam-sexe']]
            : []),
          ...(orderDataPrd?.['prdparam-type-n1']
            ? ['prdparam-type-n1', orderDataPrd['prdparam-type-n1']]
            : []),
          ...(orderDataPrd?.['prdparam-type-n2']
            ? ['prdparam-type-n2', orderDataPrd['prdparam-type-n2']]
            : []),
          ...(orderDataPrd?.['prdparam-type-n3']
            ? ['prdparam-type-n3', orderDataPrd['prdparam-type-n3']]
            : []),
          ...(orderDataPrd?.['prdparam-type-n4']
            ? ['prdparam-type-n4', orderDataPrd['prdparam-type-n4']]
            : []),
          ...(orderDataPrd?.['prdparam-photo']
            ? ['prdparam-photo', orderDataPrd['prdparam-photo']]
            : []),
        ]

  useEffect(() => {
    if (isFetched && isEALoaded && orderTags?.length) {
      clearWindowForPartners(orderTags)
      collector({ tags: window.data_layer, urlp: _urlp, collectType: 'sale' })
    }
  }, [asPath, isFetched, isEALoaded])

  return null
}

export default TagConfirm
