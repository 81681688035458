import React, { FC } from 'react'

const SalerPig: FC<any> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.40073 13.8495L4.27641 13.5316H3.93507H3.15646C2.75793 13.5316 2.5 13.2394 2.5 12.9609V10.1517C2.5 9.87232 2.75812 9.58026 3.15635 9.58026H4.25666H4.55337L4.69553 9.31983C4.9771 8.80401 5.34741 8.31329 5.80348 7.85669L6.02755 7.63236L5.92018 7.33403L5.00654 4.79533L5.00655 4.79533L5.00584 4.79339C4.9506 4.64186 4.9861 4.57999 5.00065 4.5608C5.01991 4.5368 5.12067 4.45397 5.3701 4.53145L5.37097 4.53172L9.26094 5.73233L9.40156 5.77573L9.54326 5.73603C10.4223 5.4898 11.3356 5.36571 12.2586 5.36571C15.9539 5.36571 19.013 7.30263 20.0809 9.86764L20.3513 10.7023L20.3524 10.7058L20.3609 10.7328C20.4494 11.1191 20.5 11.5112 20.5 11.9075C20.5 13.7756 19.5009 15.5668 17.6808 16.8302L17.4659 16.9793V17.2409V18.9293C17.4659 19.2089 17.2086 19.5 16.8096 19.5H15.1414C14.7416 19.5 14.4842 19.2081 14.4842 18.9293V18.8034V18.2142L13.9029 18.31C12.7949 18.4927 11.723 18.4927 10.615 18.31L10.0337 18.2142V18.8034V18.9293C10.0337 19.2085 9.77602 19.5 9.37657 19.5H7.70833C7.30964 19.5 7.05198 19.2083 7.05198 18.9293V17.2409V16.9793L6.83703 16.8301C5.68067 16.0277 4.85071 15.0002 4.40073 13.8495Z"
      fill="white"
      stroke="white"
    />
    <path
      d="M10.4598 7.37664C11.8114 7.04653 13.1869 7.04653 14.5383 7.37664C14.5684 7.38428 14.5975 7.38867 14.6277 7.38867C14.7954 7.38867 14.9476 7.27411 14.9888 7.10648C15.0387 6.90715 14.9167 6.70705 14.7171 6.65817C13.2487 6.29884 11.7511 6.29884 10.2837 6.65817C10.0833 6.70781 9.96127 6.90715 10.0112 7.10648C10.0584 7.30486 10.2587 7.428 10.4598 7.37664L10.4598 7.37664Z"
      fill="#121212"
    />
    <path
      d="M21.7283 9.38867C21.9203 10.586 22.7652 11.903 20 11.1846"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SalerPig
