import { FC } from 'react'

interface InfoProps {
  className?: string
}

const Info: FC<InfoProps> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.9987 14.6666C11.6806 14.6666 14.6654 11.6819 14.6654 7.99998C14.6654 4.31808 11.6806 1.33331 7.9987 1.33331C4.3168 1.33331 1.33203 4.31808 1.33203 7.99998C1.33203 11.6819 4.3168 14.6666 7.9987 14.6666Z"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.6667V8"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.33331H8.00667"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Info
