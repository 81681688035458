const Arrow = ({ className = '', ...props }) => (
  <svg
    className={className}
    width="10"
    height="10"
    viewBox="1 0 8 8"
    {...props}
  >
    <path d="M4 0 L 8 4 L 4 8" fill="none" stroke="currentColor"></path>
  </svg>
)

export default Arrow
