import { FC, useEffect, useRef } from 'react'

interface Props {
  htmlElement: any
  className?: string
  children?: any
  el?: HTMLElement
}

export const DangerousHtml: FC<Props> = ({
  htmlElement,
  children,
  className,
  el = 'div',
}) => {
  if (!htmlElement) return <></>

  let Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return (
    <Component
      className={className}
      dangerouslySetInnerHTML={{
        __html: htmlElement.toString(),
      }}
    >
      {children}
    </Component>
  )
}

export const DangerouslySetHtmlContent: FC<Props> = ({
  htmlElement,
  className,
  ...rest
}) => {
  const divRef = useRef(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!htmlElement || !divRef.current) {
      return
    }
    if (!isFirstRender.current) return
    isFirstRender.current = false

    const slotHtml = document
      .createRange()
      .createContextualFragment(htmlElement)
    divRef.current.innerHTML = ''
    divRef.current.appendChild(slotHtml)
  }, [htmlElement, divRef])

  return <div className={className} {...rest} ref={divRef} />
}
