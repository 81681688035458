import { FC, useState, useEffect } from 'react'

import Rainbow from '@components/icons/Rainbow'

import s from './LayoutLoading.module.css'

interface LayoutLoadingProps {
  autoPlay?: boolean
  timeToStart?: number
}

const LayoutLoading: FC<LayoutLoadingProps> = ({
  autoPlay = false,
  timeToStart = 1300,
}) => {
  const [startLayout, setStartLayout] = useState(autoPlay)

  useEffect(() => {
    if (autoPlay) return null
    const timeToStartRainbow = setTimeout(() => {
      setStartLayout(true)
    }, timeToStart)
    return () => clearTimeout(timeToStartRainbow)
  }, [])

  return (
    <>
      {startLayout && (
        <div className={s.root}>
          <Rainbow className={s.rainbow} />
        </div>
      )}
    </>
  )
}

export default LayoutLoading
