import React, { FC } from 'react'

const SalerHome: FC<any> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2L2 10H5V22H19V10H22L12 2Z"
      fill="#555555"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 22.5V15H14V22.5" fill="white" />
  </svg>
)

export default SalerHome
