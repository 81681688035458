import { FC, useEffect } from 'react'
import { useUserSettings } from '@context/session'

const TagUserInformations: FC = () => {
  const GTM_ACCOUNT = process.env.NEXT_PUBLIC_GTM_ACCOUNT || ''
  const { emailMd5, emailSha256, uid, clientType } = useUserSettings()

  useEffect(() => {
    if (window?.dataLayer && GTM_ACCOUNT?.length && !!uid) {
      window.dataLayer.push({
        userInformations: {
          loginMD5: emailMd5,
          loginSHA256: emailSha256,
          user: +uid,
          isNewCustomer: clientType !== 'ancien_client',
        },
      })
    }
  }, [uid])

  return null
}

export default TagUserInformations
