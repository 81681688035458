import { FC } from 'react'
import NextHead from 'next/head'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'

import config from '@config/seo.json'

import Favicons from '@components/common/Favicons'
import Didomi from '@components/common/Didomi'
import Eulerian from '@components/common/Eulerian'
import GoogleTagManager from '@components/common/GoogleTagManager'
import Facebook from '@components/common/Facebook'

import i18n from 'i18n.json'

const { defaultLocale } = i18n

const Head: FC = () => {
  const { locale = defaultLocale } = useRouter()
  const isTranslatableLoaded =
    typeof window !== 'undefined' && window.Translatable?.initialized

  return (
    <>
      <DefaultSeo {...config} />
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        {isTranslatableLoaded && <meta name="robots" content="noindex" />}
      </NextHead>
      <Favicons />
      <Didomi locale={locale} />
      <Eulerian />
      <GoogleTagManager />
      <Facebook />
    </>
  )
}

export default Head
