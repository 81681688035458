import React, { FC, KeyboardEventHandler } from 'react'
import useTranslation from 'next-translate/useTranslation'

import s from '../Legals/Legals.module.css'

export interface LegalsProps {
  links: Array<{
    title: string
    href: string
  }>
}

const Legals: FC<LegalsProps> = ({ links }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (!window?.Didomi) return null
    window.Didomi.preferences?.show()
  }

  const onKeyDownIndex: KeyboardEventHandler<HTMLElement> = (e) => {
    if (e?.key === 'Enter') handleClick()
  }

  if (!links?.length) return null

  return (
    <div className={s.container}>
      {links.map((link, i) => (
        <div className={s.link} key={`footer-links-legals-${i}`}>
          <a href={t(`layout:${link.href}`)}>{t(`layout:${link.title}`)}</a>
          <span className={s.slash}> | </span>
        </div>
      ))}
      <div
        className={s.link}
        onClick={() => handleClick()}
        onKeyDown={(e) => onKeyDownIndex(e)}
        aria-label={t(`layout:footer.about.cookies.title`)}
        tabIndex={0}
      >
        {t(`layout:footer.about.cookies.title`)}
      </div>
    </div>
  )
}

export default Legals
