import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import { changeLocaleIfTranslatable } from '@utils/locale'

import { Container, Link, Logo } from '@components/ui'
import { SmallableAndMe } from '@components/icons'
import UserSettings from '@components/common/Header/UserSettings'
import Banner from '@components/common/Header/Banner'
import NavigationButton from '@components/common/Header/NavigationButton'
import Search from '@components/common/Header/Search'
import UserNav from '@components/common/Header/UserNav'

import cn from 'classnames'
import s from './Header.module.css'

export interface HeaderProps {
  className?: string
  alternates?: Array<Alternate>
  locale: string
  countries?: Array<Country>
  currencies: Array<Currency>
  logoStyle?: string
}

const Header: FC<HeaderProps> = ({
  className,
  alternates,
  locale,
  countries,
  currencies,
  logoStyle,
}) => {
  const { t } = useTranslation()
  const screen = useScreen()

  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        <Banner className={s.banner} />
        <Container className={s.container}>
          <div className={s.headerLeft}>
            <NavigationButton className={s.burgerMenu} />
            <UserSettings
              locale={locale}
              alternates={alternates}
              countries={countries}
              currencies={currencies}
            />
            {typeof screen === 'undefined' ||
              ([Screen.xs, Screen.sm, Screen.md].includes(screen) && (
                <Search className={s.searchBar} />
              ))}
          </div>
          <Link
            next
            className={cn(s.headerCenter, { [s.customLogo]: logoStyle })}
            aria-label={t('layout:header.ariaGoToHome')}
            href={`/${changeLocaleIfTranslatable(locale)}`}
          >
            {logoStyle === 'loyalty' ? (
              <SmallableAndMe
                size={
                  [Screen.xs, Screen.sm, Screen.md].includes(screen) ? 150 : 180
                }
              />
            ) : (
              <>
                <Logo className={s.logo} fill="#000" />
                <p className={s.catchPhrase}>
                  {t('layout:header.catchPhrase')}
                </p>
              </>
            )}
          </Link>
          <div className={s.headerRight}>
            {![Screen.xs, Screen.sm, Screen.md].includes(screen) && (
              <Search className={s.searchBar} />
            )}
            <UserNav />
          </div>
        </Container>
      </header>
    </div>
  )
}

export default Header
