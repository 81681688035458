import { FC, useEffect, useMemo, useState } from 'react'
import onClickOutside from 'react-onclickoutside'
import FocusLock from 'react-focus-lock'
import Portal from '@reach/portal'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import { isMobileSafari } from 'react-device-detect'
import { Cross } from '@components/icons'
import cn from 'classnames'
import s from './Modal.module.css'
import { useUI } from '@components/ui/context'

export interface ModalProps {
  className?: string
  children?: any
  open?: boolean
  onClose: () => void
}

const ModalContent: FC<ModalProps> = ({ children, onClose, className }) => {
  const [isOpened, setIsOpened] = useState(false)
  useEffect(() => {
    setIsOpened(true)
  }, [])

  return (
    <div className={cn(s.modal, className, { [s.opened]: isOpened })}>
      <div className="h-10 flex justify-end items-start w-full">
        <button
          onClick={() => onClose()}
          aria-label="Close panel"
          className={s.close}
        >
          <Cross className="h-8 w-8 opacity-25" />
        </button>
      </div>
      {children}
    </div>
  )
}

const Modal: FC<ModalProps> = ({ children, open, onClose, className }) => {
  const { isSiteReverted } = useUI()
  const ModalContentOnClickOutside = useMemo(() => {
    return onClickOutside(ModalContent, {
      handleClickOutside: () => onClose,
    })
  }, [onClose])

  useEffect(() => {
    if (open) {
      blockScroll(isMobileSafari)
    } else {
      enableScroll(isMobileSafari)
    }
  }, [open])

  return (
    <Portal>
      <div dir={isSiteReverted ? 'rtl' : 'ltr'}>
        {open ? (
          <FocusLock>
            <div className={cn(s.root, { hidden: !open })}>
              <ModalContentOnClickOutside
                onClose={onClose}
                open={open}
                className={className}
              >
                {children}
              </ModalContentOnClickOutside>
            </div>
          </FocusLock>
        ) : null}
      </div>
    </Portal>
  )
}

export default Modal
