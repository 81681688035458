import { FC, useEffect, useState } from 'react'

import cn from 'classnames'
import s from './Tooltip.module.css'

interface Props {
  className?: string
  children: any
  id?: string
  position?: string
  linkRef: any
}

const Tooltip: FC<Props> = ({
  children,
  className,
  id,
  position = 'center',
  linkRef,
}) => {
  const [translateX, setTranslateX] = useState<string>('0')
  const [containerPos, setContainerPos] = useState<'fixed' | 'absolute'>(
    'absolute'
  )

  useEffect(() => {
    const getcontainerPos = () => {
      const linkBottom = linkRef?.current.getBoundingClientRect().bottom
      setContainerPos(linkBottom < -3 ? 'fixed' : `absolute`)
    }

    if (linkRef?.current) {
      getcontainerPos()
      if (position === 'center') {
        setTranslateX(`calc(-50% + ${linkRef?.current.offsetWidth / 2}px)`)
      } else if (position === 'right') {
        setTranslateX(`calc(-98% + ${linkRef?.current.offsetWidth}px)`)
      }

      window.addEventListener('scroll', getcontainerPos)
    }

    return () => window.removeEventListener('scroll', getcontainerPos)
  }, [linkRef])

  return (
    <div
      className={cn(s.root, s[position])}
      role="tooltip"
      id={id}
      style={{ transform: `translateX(${translateX})`, position: containerPos }}
    >
      <div className={cn(s.box, className)}>{children}</div>
    </div>
  )
}

export default Tooltip
