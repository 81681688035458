import React, { FC, ReactElement, useEffect, useState } from 'react'

import { useSwipeable } from 'react-swipeable'

import s from './Carousel.module.css'
import cn from 'classnames'
import { useUI } from '@components/ui/context'

interface CarouselProps {
  className?: string
  children: ReactElement[]
}

const Carousel: FC<CarouselProps> = ({ className, children }) => {
  const numItems = React.Children.count(children)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [translation, setTranslation] = useState(
    `translateX(-${currentIndex * 100}%)`
  )
  const { isSiteReverted } = useUI()

  const carouselInfiniteScroll = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + 1) % numItems
      return newIndex
    })
  }

  useEffect(() => {
    const interval = setInterval(carouselInfiniteScroll, 3000)
    return () => clearInterval(interval)
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentIndex(currentIndex + 1),
    onSwipedRight: () => setCurrentIndex(currentIndex - 1),
  })

  useEffect(() => {
    setTranslation(
      isSiteReverted
        ? `translateX(${currentIndex * 100}%)`
        : `translateX(-${currentIndex * 100}%)`
    )
    setCurrentIndex((prevIndex) => {
      const newIndex = (prevIndex + children.length) % children.length
      return newIndex
    })
  }, [currentIndex])

  return (
    <>
      <div
        {...handlers}
        className={cn(className, s.carouselContainer)}
        style={{ touchAction: 'pan-y' }}
      >
        {children.map((item, index) => (
          <div
            key={index}
            className={s.item}
            style={{ transform: translation }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className={s.controls}>
        {children.map((item, i) => (
          <div
            key={i}
            className={
              i === currentIndex ? s.activeControlButton : s.controlButton
            }
            onClick={() => {
              setCurrentIndex(i)
            }}
          />
        ))}
      </div>
    </>
  )
}

export default Carousel
