import ButtonSearchBar from '@components/common/Header/Search/ButtonSearchBar'

import cn from 'classnames'
import s from './Search.module.css'

export interface SearchPropsTypes {
  className?: string
}

const Search = ({ className }: SearchPropsTypes) => (
  <div className={cn(s.root, className)}>
    <ButtonSearchBar id="searchBar" className={s.searchBarMobileIcon} />
  </div>
)

export default Search
