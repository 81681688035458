export const getShop = (): Store | null => {
  if (typeof window !== 'undefined') {
    try {
      const rep = localStorage?.getItem('smallable_saler_shop')
      return rep && JSON.parse(rep)
    } catch (err) {
      return null
    }
  }
  return null
}

export const getPrinter = (): Printer | null => {
  if (typeof window !== 'undefined') {
    try {
      const rep = localStorage?.getItem('smallable_saler_printer')
      return rep && JSON.parse(rep)
    } catch (err) {
      return null
    }
  }
  return null
}

export const getPose = (): Pose | null => {
  if (typeof window !== 'undefined') {
    try {
      const rep = localStorage?.getItem('smallable_saler_pos')
      return rep && JSON.parse(rep)
    } catch (err) {
      return null
    }
  }
  return null
}

export const setLocalShop = (shop: string): void => {
  if (typeof window !== 'undefined') {
    localStorage?.setItem('smallable_saler_shop', JSON.stringify(shop))
  }
}

export const setLocalPrinter = (printer: string): void => {
  if (typeof window !== 'undefined') {
    localStorage?.setItem('smallable_saler_printer', JSON.stringify(printer))
  }
}

export const setLocalPose = (pose: string): void => {
  if (typeof window !== 'undefined') {
    localStorage?.setItem('smallable_saler_pos', JSON.stringify(pose))
  }
}
