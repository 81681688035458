import { FC, useEffect, useState, useRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import useWindowScroll from '@lib/hooks/useWindowScroll'

import { Container } from '@components/ui'
import NavbarUserSetting from './NavbarUserSetting'
import NavbarItem from './NavbarItem'
import NavbarFooter from './NavbarFooter'

import { fetchNavigation } from '@mw/navigation'
import { blockScroll, enableScroll } from '@utils/scrollLock'
import { enableHtml100vh, disableHtml100vh } from '@utils/html100vh'
import { isMobileSafari } from 'react-device-detect'

import cn from 'classnames'
import s from './Navbar.module.css'

const STICKY_TOP_SCROLL_Y = 159

export interface NavbarProps {
  className?: string
  navigation: NavigationItem[]
  alternates?: Array<Alternate>
  locale: string
  currencies: Array<Currency>
  countries?: Array<Country>
}

const Navbar: FC<NavbarProps> = ({
  className,
  navigation: initialNavigation,
  alternates,
  locale,
  currencies,
  countries,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { deliveryCountryCode, sales, roles } = useUserSettings()
  const { closeNavigation, displayNavigation } = useUI()
  const screen = useScreen()

  const formerDeliveryCountryCode = useRef(deliveryCountryCode)
  const [navigation, setNavigation] = useState(initialNavigation)
  const [expandedItem, setExpandedItem] = useState<number | undefined>(
    undefined
  )
  const isNavigationOpen = useRef(displayNavigation)

  const { y: scrollY } = useWindowScroll()
  const [withStickyTop, setWithStickyTop] = useState(
    scrollY >= STICKY_TOP_SCROLL_Y
  )

  useEffect(() => {
    const onRouteChangeComplete = () => {
      if (isNavigationOpen) closeNavigation()
    }
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [])

  useEffect(() => {
    if (
      displayNavigation &&
      screen &&
      [Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(screen)
    ) {
      blockScroll(isMobileSafari)
    } else {
      enableScroll(isMobileSafari)
    }
  }, [displayNavigation])

  useEffect(() => {
    if (
      displayNavigation &&
      screen &&
      ![Screen.xs, Screen.sm, Screen.md, Screen.lg].includes(screen)
    ) {
      closeNavigation()
      enableScroll(isMobileSafari)
    } else if (displayNavigation) {
      blockScroll(isMobileSafari)
    }
  }, [displayNavigation, screen, closeNavigation])

  useEffect(() => {
    if (displayNavigation && isMobileSafari) enableHtml100vh()
    else {
      disableHtml100vh()
    }
  }, [displayNavigation])

  useEffect(() => {
    isNavigationOpen.current = displayNavigation
    if (!displayNavigation) setExpandedItem(undefined)
  }, [displayNavigation])

  useEffect(() => {
    if (withStickyTop !== scrollY >= STICKY_TOP_SCROLL_Y) {
      setWithStickyTop(scrollY >= STICKY_TOP_SCROLL_Y)
    }
  }, [scrollY, withStickyTop, setWithStickyTop])

  useEffect(() => {
    ;(async () => {
      if (deliveryCountryCode !== formerDeliveryCountryCode.current) {
        formerDeliveryCountryCode.current = deliveryCountryCode
        try {
          const navigationData = await fetchNavigation({
            locale,
            countryCode: deliveryCountryCode,
            sales,
            roles,
          })
          if (navigationData.length) setNavigation(navigationData)
        } catch (err) {
          console.error(err)
        }
      }
    })()
  }, [deliveryCountryCode])

  return (
    <div>
      <div
        className={cn(s.overlay, { [s.opened]: displayNavigation })}
        aria-label={t('layout:navigation.ariaCloseMenu')}
        role="button"
        onClick={() => closeNavigation()}
      />
      <nav
        className={cn(s.nav, className, { [s.opened]: displayNavigation })}
        aria-label={t('layout:navigation.ariaLabel')}
      >
        <Container>
          <NavbarUserSetting
            alternates={alternates}
            currencies={currencies}
            countries={countries}
          />
          <ul className={s.list}>
            {navigation?.map((navItem: NavigationItem, index: number) => (
              <NavbarItem
                key={index}
                item={navItem}
                isExpanded={expandedItem === index}
                expand={() => setExpandedItem(index)}
                collapse={() => setExpandedItem(undefined)}
                withStickyTop={withStickyTop}
              />
            ))}
          </ul>
          <NavbarFooter />
        </Container>
      </nav>
    </div>
  )
}

export default Navbar
