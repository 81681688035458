import { RefObject } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Status } from '@components/common/Alert/Alert'
import { PasswordRuleStatus } from '@components/form/PasswordChecklist/PasswordChecklist'
import {
  PASSWORD_LENGTH_REGEXP,
  PASSWORD_LOWERCASE_REGEXP,
  PASSWORD_NUMBER_REGEXP,
  PASSWORD_UPPERCASE_REGEXP,
} from '@constants'

export type RegisterFormDataProp = {
  mandatory: {
    username: string
    password: string
    firstName: string
    lastName: string
    gender: number
    facebookId: string
    country: number
    address1: string
    zipCode: string
    city: string
    phoneNumber: string
    birthdayDay: string
    birthdayMonth: string
    birthdayYear: string
    optinLoyalty: boolean
  }
  optional?: {
    company?: string
    address2?: string
    state?: number
    hasNewsletter?: boolean
    newsletterLanguage?: string
    isChildComming?: boolean
    unBornChild?: {
      birthdayDay?: string
      birthdayMonth?: string
      birthdayYear?: string
    }
    hasChildren?: boolean
    childrenInfos?: {
      gender?: string
      firstName?: string
      birthday?: string
    }[]
  }
}

export const getDefaultFormData = ({ locale }: { locale: string }) => {
  return {
    mandatory: {
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      facebookId: '',
      gender: 2,
      country: 7,
      address1: '',
      zipCode: '',
      city: '',
      phoneNumber: '',
      birthdayDay: '01',
      birthdayMonth: '01',
      birthdayYear: (new Date().getFullYear() - 40).toString(),
      optinLoyalty: false,
    },
    optional: {
      company: '',
      address2: '',
      state: null,
      hasNewsletter: false,
      newsletterLanguage: locale,
      isChildComming: false,
      unBornChild: {
        birthdayDay: '01',
        birthdayMonth: '01',
        birthdayYear: (new Date().getFullYear() + 1).toString(),
      },
      childrenInfos: [],
    },
  }
}

export const getFormData = ({
  locale,
  currencyCode,
  refFormData,
  recaptchaToken,
}: {
  locale: string
  currencyCode: string
  refFormData: RefObject<RegisterFormDataProp>
  recaptchaToken: string
}) => {
  return {
    recaptchaToken,
    optinLoyalty: refFormData?.current?.mandatory?.optinLoyalty,
    customer: {
      email: refFormData?.current?.mandatory?.username,
      password: refFormData?.current?.mandatory?.password,
      firstName: refFormData?.current?.mandatory?.firstName,
      lastName: refFormData?.current?.mandatory?.lastName,
      gender: Number(refFormData?.current?.mandatory?.gender),
      birthday: `${refFormData?.current?.mandatory?.birthdayYear}-${refFormData?.current?.mandatory?.birthdayMonth}-${refFormData?.current?.mandatory?.birthdayDay}`,
      facebookId: refFormData?.current?.mandatory?.facebookId,
      locale: locale,
      currency: currencyCode,
    },
    address: {
      country: refFormData?.current?.mandatory?.country,
      street1: refFormData?.current?.mandatory?.address1,
      street2: refFormData?.current?.optional?.address2,
      zipCode: refFormData?.current?.mandatory?.zipCode,
      city: refFormData?.current?.mandatory?.city,
      state: refFormData?.current?.optional?.state,
      phoneNumber: refFormData?.current?.mandatory?.phoneNumber,
      company: refFormData?.current?.optional?.company,
    },
    newsletter: {
      hasNewsletter: refFormData?.current?.optional?.hasNewsletter,
      language: refFormData?.current?.optional?.hasNewsletter
        ? refFormData?.current?.optional?.newsletterLanguage
        : '',
    },
    children: {
      unborn: refFormData?.current?.optional?.isChildComming
        ? [
            {
              birthday: `${refFormData?.current?.optional?.unBornChild?.birthdayYear}-${refFormData?.current?.optional?.unBornChild?.birthdayMonth}-${refFormData?.current?.optional?.unBornChild?.birthdayDay}`,
            },
          ]
        : [],
      born: refFormData?.current?.optional?.childrenInfos?.length
        ? refFormData?.current?.optional?.childrenInfos
        : [],
    },
  }
}

export enum Error {
  EmptyField = 'empty field',
  EmailAlreadyExists = 'email already exists',
  InvalidEmail = 'invalid email',
  PasswordRules = 'password rules',
  InvalidZipCode = 'invalid zip code',
  WrongFormatNumber = 'number format is wrong',
}

export const passwordRules = [
  {
    name: 'length',
    status: PasswordRuleStatus.FormInfo,
    value: PASSWORD_LENGTH_REGEXP,
    text: 'layout:header.modalLogin.password_rules.length',
  },
  {
    name: 'lowercase',
    status: PasswordRuleStatus.FormInfo,
    value: PASSWORD_LOWERCASE_REGEXP,
    text: 'layout:header.modalLogin.password_rules.lowercase',
  },
  {
    name: 'uppercase',
    status: PasswordRuleStatus.FormInfo,
    value: PASSWORD_UPPERCASE_REGEXP,
    text: 'layout:header.modalLogin.password_rules.uppercase',
  },
  {
    name: 'number',
    status: PasswordRuleStatus.FormInfo,
    value: PASSWORD_NUMBER_REGEXP,
    text: 'layout:header.modalLogin.password_rules.number',
  },
]

export const FormErrors = () => {
  const { t } = useTranslation()

  return {
    emptyField: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_emptyField'),
      type: Error.EmptyField,
    },
    username: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_invalidEmail'),
      type: Error.InvalidEmail,
    },
    usernameExists: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_emailExist'),
      type: Error.EmailAlreadyExists,
    },
    password: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_invalidPWD'),
      type: Error.PasswordRules,
    },
    passwordRules: {
      status: Status.FormInfo,
      message: t('layout:header.modalLogin.message.error_invalidPWD'),
      type: Error.PasswordRules,
    },
    zipCode: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_invalidZipCode'),
      type: Error.InvalidZipCode,
    },
    phoneNumber: {
      status: Status.FormError,
      message: t('layout:header.modalLogin.message.error_phoneNumberInvalid'),
      type: Error.WrongFormatNumber,
    },
  }
}
