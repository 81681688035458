import cn from 'classnames'
import React, { ButtonHTMLAttributes, FC } from 'react'

import s from './Button.module.css'

import { Link } from '@components/ui'
import type { LinkProps } from '@components/ui/Link'
import LoadingDots from '@components/ui/LoadingDots'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  LinkProps & {
    icon?: () => JSX.Element
    iconClassName?: string
    loading?: boolean
  }

const ButtonContent = ({
  children,
  icon: Icon,
  iconClassName,
}: {
  children: React.ReactNode
  icon?: () => JSX.Element
  iconClassName?: string
}) => (
  <>
    {Icon && (
      <span className={cn(s.icon, iconClassName)}>
        <Icon />
      </span>
    )}
    <span>{children}</span>
  </>
)

const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    href,
    icon: Icon,
    iconClassName,
    loading,
    ...rest
  } = props
  const buttonClassName = cn(
    s.root,
    {
      [s.rootLoading]: loading && !href,
      [s.rootWithIcon]: !!Icon,
    },
    className
  )

  return href ? (
    <Link href={href} className={buttonClassName} {...rest}>
      <ButtonContent icon={Icon} iconClassName={iconClassName}>
        {children}
      </ButtonContent>
    </Link>
  ) : loading ? (
    <span className={buttonClassName}>
      <span className={s.placeholder}>{children}</span>
      <LoadingDots />
    </span>
  ) : (
    <button className={buttonClassName} {...rest}>
      <ButtonContent icon={Icon} iconClassName={iconClassName}>
        {children}
      </ButtonContent>
    </button>
  )
}

export default Button
