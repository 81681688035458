import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import Alert, { Status } from '@components/common/Alert/Alert'
import { tagEventCS } from '@components/common/ContentSquare'
import { Error, FormErrors } from '@components/form'

import parsePhoneNumber, { getCountryCallingCode } from 'libphonenumber-js'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import fr from 'react-phone-input-2/lang/fr.json'
import de from 'react-phone-input-2/lang/de.json'
import it from 'react-phone-input-2/lang/it.json'
import es from 'react-phone-input-2/lang/es.json'

import cn from 'classnames'
import s from './PhoneNumberInput.module.css'

interface PhoneNumberInputProps {
  selectedCountry?: number
  countries?: Country[]
  required?: boolean
  setData?: any
  setErrors?: any
  errors?: any
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  selectedCountry,
  countries,
  required = false,
  setData,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const errorsObject = FormErrors()

  const [countryToInput, setCountryToInput] = useState('')

  const countriesArray = countries?.map((country) =>
    country?.id !== 58 ? country.isoCode.toLowerCase() : 'gb'
  )

  let localeToUse = ((locale) => {
    switch (locale) {
      case 'fr':
        return fr
      case 'de':
        return de
      case 'it':
        return it
      case 'es':
        return es
      default:
        return {}
    }
  })(locale)

  //todo check pour les iles canaries (idem Espagne)
  const prefixs = []
  const filteredCountries = countries?.filter(
    (country) => country.isoCode !== 'IC'
  )
  for (let i = 0; i < filteredCountries?.length; i++) {
    prefixs.push({
      option: getCountryCallingCode(filteredCountries[i].isoCode as any),
      value: countries[i].id,
    })
  }

  const [prefix, setPrefix] = useState<string>('')

  const [dataInput, setDataInput] = useState<{
    data: string
    error: {
      status: Status | null
      message: string
      type: Error | null
    }
  }>({ data: '', error: { status: null, message: '', type: null } })

  const handleOnBlur = () => {
    const parseNumber = parsePhoneNumber(`+${dataInput?.data}`)

    if (dataInput?.data.length === 0) {
      required &&
        setErrors({
          ...errors,
          phoneNumber: errorsObject?.emptyField,
        })
      return
    }

    if (!parseNumber?.isValid()) {
      required &&
        setErrors({
          ...errors,
          phoneNumber: errorsObject?.phoneNumber,
        })
      tagEventCS({
        eventName: 'phoneNumber_error',
        eventType: 'trackPageEvent',
      })
      return
    }
    required &&
      setErrors({
        ...errors,
        phoneNumber: 'ok',
      })
    setData({
      mandatory: required ? 'mandatory' : 'optional',
      name: 'phoneNumber',
      data: parseNumber?.number,
    })
  }

  useEffect(() => {
    const countrySelected = countries?.filter(
      (country) => country.id == selectedCountry
    )
    if (!countrySelected) return null
    setPrefix(getCountryCallingCode(countrySelected[0].isoCode as any))
    setCountryToInput(countrySelected[0].isoCode.toLowerCase())
    setDataInput({ ...dataInput, data: '' })
    setData({
      mandatory: 'mandatory',
      name: 'phoneNumber',
      data: '',
    })
  }, [selectedCountry])

  useEffect(() => {
    required &&
      setErrors({
        ...errors,
        phoneNumber: null,
      })
  }, [dataInput?.data, prefix])

  useEffect(() => {
    if (dataInput?.data.length > 0) {
      handleOnBlur()
    }
  }, [prefix])

  return (
    <>
      <PhoneInput
        specialLabel={t(`layout:header.modalLogin.register.phoneNumber`) + '*'}
        inputClass={cn(s.phoneInput, {
          [s.errorInput]:
            errors?.phoneNumber?.type === Error.EmptyField ||
            errors?.phoneNumber?.type === Error.WrongFormatNumber,
        })}
        inputProps={{ id: 'phoneNumber' }}
        containerClass={s.phoneInputContainer}
        onlyCountries={countriesArray}
        country={countryToInput}
        onChange={(e) => setDataInput({ ...dataInput, data: e })}
        onBlur={handleOnBlur}
        countryCodeEditable={false}
        localization={localeToUse}
        buttonClass={s.phoneInputButton}
        dropdownClass={s.phoneInputDropdown}
        autoFormat={false}
        enableTerritories
      />
      {errors?.phoneNumber &&
        errors?.phoneNumber?.status &&
        errors?.phoneNumber?.message && (
          <Alert
            status={errors?.phoneNumber?.status}
            message={errors?.phoneNumber?.message}
            className={s.alertMessage}
          />
        )}
    </>
  )
}

export default PhoneNumberInput
