import React, { useState, useEffect, LegacyRef } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { CrossedOutEye, Eye } from '@components/icons'
import Alert, { Status } from '@components/common/Alert/Alert'

import cn from 'classnames'
import s from './DynamicInput.module.css'

export interface DynamicInputProps {
  id: string
  ref?: LegacyRef<HTMLInputElement>
  className?: string
  inputName: string
  type: string
  value?: string
  autoComplete?: string
  required?: boolean
  maxLength?: number
  optional?: boolean
  label: string
  ariaLabel: string
  errorState?: {
    status: Status
    message: string
  }
  onChange: (...args: any[]) => void
  onBlur?: (...args: any[]) => void
  onFocus?: () => void
  errors?: any
}

// Pass restProp to the component

const DynamicInput: React.FC<DynamicInputProps> = ({
  id,
  ref,
  className,
  inputName,
  type = 'text',
  autoComplete = 'off',
  value = '',
  required = false,
  maxLength = 100,
  optional = false,
  label,
  ariaLabel,
  errorState,
  onChange,
  onBlur,
  onFocus,
  ...restProps
}) => {
  const { t } = useTranslation()

  const [valueInput, setValueInput] = useState(value)
  const [isUp, setIsUp] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [inputType, setInputType] = useState('')

  useEffect(() => {
    setValueInput(value)
  }, [value])

  useEffect(() => {
    setIsUp(!!valueInput?.length)
  }, [valueInput])

  useEffect(() => {
    if (type === 'password')
      setInputType(() => (isPasswordVisible ? 'text' : 'password'))
    else setInputType(type)
  }, [isPasswordVisible])

  if (!inputName?.length) return null

  return (
    <div className={s.container} {...restProps}>
      <div className={s.formItem}>
        {type === 'password' && (
          <div
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            className={s.eye}
          >
            {isPasswordVisible ? <CrossedOutEye /> : <Eye />}{' '}
          </div>
        )}
        {optional && valueInput === '' && !isFocus && (
          <div className={s.optional}>
            {t(`layout:header.modalLogin.register.optional`)}
          </div>
        )}
        <input
          ref={ref}
          id={id}
          className={cn(s.formInput, className, {
            [s.isPassword]: type === 'password',
          })}
          name={inputName}
          type={inputType}
          value={valueInput}
          aria-label={ariaLabel}
          required={required}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onChange={(e) => {
            setValueInput(e.target.value)
            onChange(e)
          }}
          onBlur={(e) => {
            onBlur(e)
            setIsFocus(false)
          }}
          onFocus={(e) => {
            setIsFocus(true)
            if (typeof onFocus !== 'undefined') onFocus()
          }}
          data-cs-mask
        />
        {label?.length ? (
          <label htmlFor={id} className={cn(s.formLabel, { [s.formUp]: isUp })}>
            {label}
          </label>
        ) : null}
      </div>
      {errorState && errorState.status && errorState.message && (
        <Alert
          status={errorState.status}
          message={errorState.message}
          className={s.alertMessage}
        />
      )}
    </div>
  )
}

export default DynamicInput
