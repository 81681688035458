import { FC, useState, FormEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'

import { Arrow } from '@components/icons'

import { MODALS } from '@constants'

import cn from 'classnames'
import s from './Newsletter.module.css'

export interface NewsletterProps {
  className?: string
}

const Newsletter: FC<NewsletterProps> = ({ className }) => {
  const { t } = useTranslation()
  const { setModalView, openModal } = useUI()

  const [value, setValue] = useState('')

  const openNewsletterModal = async ({
    e,
    autoDisplay,
  }: {
    e?: FormEvent
    autoDisplay: boolean
  }) => {
    e?.preventDefault()

    const props = {
      email: value,
      autoDisplay,
    }
    setModalView(MODALS.NEWSLETTER, props)
    openModal()
  }

  return (
    <section className={cn(s.container, className)}>
      <h2 className={s.title}>{t('layout:footer.newsletter.label')}</h2>
      <p className={s.text}>{t('layout:footer.newsletter.text')}</p>
      <form
        className={s.form}
        action="#"
        method="get"
        onSubmit={(e) => openNewsletterModal({ e, autoDisplay: false })}
      >
        <input
          className={s.input}
          type="email"
          name="email"
          value={value}
          placeholder="E-mail*"
          onChange={(e) => setValue(e.target.value)}
          autoCorrect="off"
          data-cs-mask
        />
        <button
          className={s.button}
          onClick={(e) => openNewsletterModal({ e, autoDisplay: false })}
          aria-label={t('newsletter:submit')}
        >
          <Arrow
            width="1.5rem"
            height="1.5rem"
            stroke="#121212"
            strokeWidth={2}
          />
        </button>
      </form>
    </section>
  )
}

export default Newsletter
