import React, { FC, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import FormLogIn from '@components/common/AccountForm/FormLogin'
import FormResetPwd from '@components/common/AccountForm/FormResetPwd'
import WithFacebook from '@components/common/WithFacebook'
import { Button, Link } from '@components/ui'

import cn from 'classnames'
import s from './SigninModul.module.css'

export interface SigninModulProps {
  className?: string
  isModal?: boolean
  redirectTo?: string
  referer?: string
}

const SigninModul: FC<SigninModulProps> = ({
  className,
  isModal = false,
  redirectTo,
  referer,
}) => {
  const { t } = useTranslation()
  const [resetWanted, setResetWanted] = useState<boolean>(false)
  const [resetSuccessful, setResetSuccessFul] = useState<boolean>(false)
  const [head, setHead] = useState<{
    title: string
    text?: string
    span: string
  }>({
    title: t('layout:header.modalLogin.login.header.title'),
    text: t('layout:header.modalLogin.login.header.text'),
    span: t('layout:header.modalLogin.login.header.createAccount'),
  })

  const handleBackToConnection = (e) => {
    e.preventDefault()
    setResetSuccessFul(false)
    setResetWanted(false)
  }

  useEffect(() => {
    if (!resetSuccessful && !resetWanted) {
      setHead({
        title: t('layout:header.modalLogin.login.header.title'),
        text: t('layout:header.modalLogin.login.header.text'),
        span: t('layout:header.modalLogin.login.header.createAccount'),
      })
    }
    if (resetWanted) {
      setHead({
        title: t('layout:header.modalLogin.reset.header.title'),
        text: t('layout:header.modalLogin.reset.text'),
        span: t('layout:header.modalLogin.reset.text2'),
      })
    }
    if (resetSuccessful) {
      setHead({
        title: t('layout:header.modalLogin.reset.done'),
        text: t('layout:header.modalLogin.reset.text'),
        span: t('layout:header.modalLogin.reset.text2'),
      })
    }
    window.scrollTo({ top: 0 })
  }, [resetWanted, resetSuccessful])

  return (
    <>
      <div className={cn(s.container, className)}>
        <div className={s.header}>
          <h1 className={s.title}>{head.title}</h1>
          <div className={s.text}>
            {!resetWanted && !resetSuccessful ? (
              <p>
                {head?.text}
                <Link next={true} href="/register" className={s.link}>
                  {head.span}
                </Link>
                .
              </p>
            ) : (
              <p>
                {head?.text}
                <button className={s.link} onClick={handleBackToConnection}>
                  {head?.span}
                </button>
                .
              </p>
            )}
          </div>
        </div>
        <div className={s.modalContent}>
          {!resetSuccessful ? (
            !resetWanted ? (
              <>
                <div className={s.formContent}>
                  <div className={s.login}>
                    <FormLogIn
                      handleReset={setResetWanted}
                      isModal={isModal}
                      referer={referer}
                      redirectTo={redirectTo}
                    />
                  </div>
                </div>
                <div className={s.modalRow}>
                  <WithFacebook isModal={isModal} referer={referer} />
                </div>
              </>
            ) : (
              <FormResetPwd handleResetSuccess={setResetSuccessFul} />
            )
          ) : (
            <div className={s.resetSuccesPage}>
              <p className={s.resetSuccessInfo}>
                {t('layout:header.modalLogin.reset.confirm')}
              </p>
              <Button
                className={s.ctaBackConnection}
                onClick={handleBackToConnection}
              >
                {t('layout:header.modalLogin.reset.switcher')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SigninModul
