
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { FC, useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import Router from 'next/router'

import { ManagedUIContext } from '@components/ui/context'
import { UserSettingsProvider } from '@context/session'
import { Head } from '@components/common'
import { ApolloProvider } from '@apollo/client'
import client from '@lib/apolloClient'

import 'focus-visible'

import '@assets/main.css'

import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import smoothscroll from 'smoothscroll-polyfill'
import { LayoutLoading } from '@components/ui'

const Noop: FC = ({ children }) => <>{children}</>

const __Page_Next_Translate__ = function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeStart', () => setIsLoading(true))
    Router.events.on('routeChangeComplete', () => setIsLoading(false))
    Router.events.on('routeChangeError', () => setIsLoading(false))
    smoothscroll.polyfill()
  }, [])

  return (
    <ApolloProvider client={client}>
      <UserSettingsProvider sessionData={pageProps.session || null}>
        <ManagedUIContext>
          <Head />
          <Layout pageProps={pageProps}>
            {isLoading && <LayoutLoading />}
            <Component {...pageProps} />
          </Layout>
        </ManagedUIContext>
      </UserSettingsProvider>
    </ApolloProvider>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  