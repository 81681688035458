import React, { FC } from 'react'

const SalerBook: FC<any> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5 2H20V19C20 19 19.5 19.5 17.5 22H6.5C5.83696 22 5.20107 21.7366 4.73223 21.2678C4.26339 20.7989 4 20.163 4 19.5V4.5C4 3.83696 4.26339 3.20107 4.73223 2.73223C5.20107 2.26339 5.83696 2 6.5 2Z"
      fill="white"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 19V2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H16.5"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 22V5H6C4 5 4.16667 4.66667 4 4V20C4 21 5 22 6 22H17Z"
      fill="#555555"
      stroke="#555555"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SalerBook
