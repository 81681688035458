const DeliveryPackage = ({ width = '21', height = '20', ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7923 1.66699L19.1257 5.83366V14.167L10.7923 18.3337L2.45898 14.167L2.45898 5.83366L10.7923 1.66699Z"
      stroke="#121212"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1257 5.83398L10.7923 10.0007L2.45898 5.83398"
      stroke="#121212"
      strokeLinecap="round"
    />
    <path
      d="M15.4212 10.926V7.68522L7.08789 3.51855"
      stroke="#121212"
      strokeLinecap="round"
    />
    <path d="M10.793 17.8704V10" stroke="#121212" strokeLinecap="round" />
  </svg>
)

export default DeliveryPackage
