import React from 'react'

import NextHead from 'next/head'
declare global {
  interface Window {
    fbAsyncInit: any
    FB: any
  }
}

const Facebook = () => {
  const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''
  if (!FACEBOOK_APP_ID?.length) return null

  const fbScript = `(window.fbAsyncInit = function () {
    window.FB.init({
      appId: '${FACEBOOK_APP_ID}',
      cookie: true,
      xfbml: true,
      version: 'v14.0',
    })
    window.FB.AppEvents.logPageView()
  })

  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')`

  return (
    <NextHead>
      <script dangerouslySetInnerHTML={{ __html: fbScript }}></script>
    </NextHead>
  )
}

export default Facebook
