import { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import useTooltip from '@components/ui/Tooltip/useTooltip'

import MediaQueries from '@components/common/MediaQueries'
import SubUserNav from '@components/common/Header/UserNav/SubUserNav'
import BasketSlider from '@components/common/Header/UserNav/BasketSlider'
import BasketModal from '@components/common/Header/UserNav/BasketModal'
import {
  Basket,
  CheckConnected,
  CustomerService,
  Gift,
  Heart,
  ProfilNotLogged,
} from '@components/icons'
import Link from '@components/ui/Link'

import { MODALS, TOOLTIPS } from '@constants'
import { getItemsCount } from '@utils/basket/items'

import cn from 'classnames'
import s from './UserNav.module.css'

export interface UserNavProps {
  className?: string
}

const UserNav: FC<UserNavProps> = ({ className }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { firstname, lastname, logged, basket, roles } = useUserSettings()
  const { setModalView, openModal } = useUI()

  const [animation, setAnimation] = useState(false)

  const accountLinkRef = useRef<HTMLDivElement>()
  const basketLinkRef = useRef<HTMLDivElement>()

  const itemsCount = basket ? getItemsCount(basket) : 0
  const [basketOpen, setBasketOpen] = useTooltip(TOOLTIPS.BASKET)

  const hasTelesales = roles?.includes('ROLE_ALLOWED_TO_SWITCH')
  const hasPreviousAdmin = roles?.includes('ROLE_PREVIOUS_ADMIN')
  const hasSaler = roles?.includes('ROLE_SALER')
  const hasSalerTPE = roles?.includes('ROLE_SALER_TPE_ONLY')

  const openAccountModal = (e: MouseEvent) => {
    e?.preventDefault()
    setModalView(MODALS.ACCOUNT)
    openModal()
  }

  const openTelesalesModal = (e: MouseEvent) => {
    e?.preventDefault()
    setModalView(MODALS.TELESALES)
    openModal()
  }

  const AnimationTimer = setTimeout(() => {
    setAnimation(false)
  }, 500)

  const stopAnimationTimer = () => {
    clearTimeout(AnimationTimer)
  }

  useEffect(() => {
    if (basketOpen && itemsCount === 0) setBasketOpen(false)
  }, [itemsCount])

  useEffect(() => {
    setAnimation(true)
    AnimationTimer
    stopAnimationTimer()
  }, [itemsCount])

  return (
    <div className={cn(className, s.root)}>
      <div className={s.links}>
        {(hasTelesales || hasSaler || hasSalerTPE) && !hasPreviousAdmin && (
          <button
            className={s.telesales}
            onClick={openTelesalesModal}
            aria-label={t('layout:header.telesales.title')}
          >
            <CustomerService width="1.5rem" height="1.5rem" />
          </button>
        )}
        <MediaQueries hidden={['xs', 'sm', 'md']}>
          <>
            <a
              className={s.link}
              href={t('layout:header.links.giftList.href')}
              rel="nofollow"
              aria-label={t('layout:header.links.giftList.title')}
            >
              <Gift size="1.5rem" />
            </a>
            <Link
              href={t('layout:header.links.wishList.href')}
              next
              rel="nofollow"
              aria-label={t('layout:header.links.wishList.title')}
            >
              <div className={s.link}>
                <Heart size="1.5rem" />
              </div>
            </Link>
          </>
        </MediaQueries>
        {logged ? (
          <div ref={accountLinkRef} className={s.link}>
            <SubUserNav
              icon={
                <>
                  <ProfilNotLogged size="1.5rem" />
                  <CheckConnected className={s.checked} />
                </>
              }
              href={t('layout:header.links.account.href')}
              id="TOOLTIP_ACCOUNT"
              position="right"
              linkRef={accountLinkRef}
              ariaLabel={t('layout:header.links.account.title')}
            >
              <a
                className={s.linkText}
                href={t('layout:header.links.accessAccount.href')}
                rel="nofollow"
                arial-label={t('layout:header.links.accessAccount.title')}
              >
                {t('layout:header.links.accessAccount.title')}
              </a>
              <p className={s.sublink}>
                {t('layout:header.links.welcome')} {firstname} {lastname}&nbsp;!
              </p>
              <a
                className={s.linkText}
                href={t('layout:header.links.followOrder.href')}
                rel="nofollow"
                aria-label={t('layout:header.links.followOrder.title')}
              >
                {t('layout:header.links.followOrder.title')}
              </a>
              <a
                className={s.linkText}
                href={t('layout:header.links.logout.href')}
                rel="nofollow"
                arial-label={t('layout:header.links.logout.title')}
              >
                {t('layout:header.links.logout.title')}
              </a>
            </SubUserNav>
          </div>
        ) : (
          <a
            className={s.link}
            onClick={openAccountModal}
            href={t('layout:header.links.account.href')}
            rel="nofollow"
            aria-label={t('layout:header.links.account.title')}
          >
            <ProfilNotLogged size="1.5rem" />
          </a>
        )}

        {hasPreviousAdmin && (
          <p className={s.previousAdmin}>
            {firstname}&nbsp;{lastname}&nbsp;
            <a
              className={s.linkLogout}
              href={`/${locale}/account?_switch_user=_exit`}
            >
              {t('layout:header.telesales.logout')}
            </a>
          </p>
        )}
        <div ref={basketLinkRef} className={s.link}>
          <MediaQueries hidden={['xs', 'sm', 'md']} seoReadable={true}>
            {basket && itemsCount && itemsCount !== 0 ? (
              <SubUserNav
                title={
                  <>
                    <Basket width="1.5rem" height="1.5rem" />
                    <span className={s.basketIconCount}>{itemsCount}</span>
                  </>
                }
                href={t('layout:header.links.basket.href')}
                id={TOOLTIPS.BASKET}
                tooltipState={[basketOpen, setBasketOpen]}
                position="right"
                linkRef={basketLinkRef}
                className={s.basketIcon}
                arialLabel={t('layout:header.links.basket.title')}
              >
                <BasketSlider basket={basket} itemsCount={itemsCount} />
              </SubUserNav>
            ) : (
              <a
                className={s.basketIcon}
                href={t('layout:header.links.basket.href')}
                aria-label={t('layout:header.links.basket.title')}
              >
                <Basket width="1.5rem" height="1.5rem" />
                <span className={s.basketIconCount}>{itemsCount}</span>
              </a>
            )}
          </MediaQueries>
          <MediaQueries visible={['xs', 'sm', 'md']} seoReadable={false}>
            <>
              <a
                className={s.basketIcon}
                href={t('layout:header.links.basket.href')}
                aria-label={t('layout:header.links.basket.title')}
                onClick={(e) => {
                  if (basket && itemsCount > 0) {
                    e.preventDefault()
                    setBasketOpen(!basketOpen)
                  }
                }}
              >
                <Basket width="1.5rem" height="1.5rem" />
                {basket && (
                  <span
                    className={
                      animation ? s.basketIconCountAnimation : s.basketIconCount
                    }
                  >
                    {itemsCount}
                  </span>
                )}
              </a>
              <BasketModal
                basket={basket}
                itemsCount={itemsCount}
                basketOpen={basketOpen}
                setBasketOpen={setBasketOpen}
              />
            </>
          </MediaQueries>
        </div>
      </div>
    </div>
  )
}

export default UserNav
