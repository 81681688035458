import React, { FC } from 'react'

import cn from 'classnames'
import s from './SideMedia.module.css'

interface SideMediaProps {
  className?: string
  url: string
  isRegister?: boolean
}

const SideMedia: FC<SideMediaProps> = ({
  className,
  url,
  isRegister = false,
}) => (
  <div
    className={cn(s.container, className, { [s.register]: isRegister })}
    style={{ backgroundImage: `url(${url || ''})` }}
  />
)

export default SideMedia
