import { FC } from 'react'
import s from './Alert.module.css'
import cn from 'classnames'

export enum Status {
  Error = 'error',
  FormError = 'formError',
  FormInfo = 'formInfo',
  Success = 'success',
  Info = 'info',
}

export interface Notification {
  status: Status
  message: string
  className?: string
}

const Alert: FC<Notification> = ({ status, message, className }) => {
  const style = [s.alert]

  if (status === Status.Error) style.push(s.error)
  else if (status === Status.FormError) style.push(s.formError)
  else if (status === Status.FormInfo) style.push(s.formInfo)
  else if (status === Status.Success) style.push(s.success)
  else if (status === Status.Info) style.push(s.infos)
  else style.push(s.default)

  return (
    <p role="alert" className={cn(className, ...style)}>
      {message}
    </p>
  )
}

export default Alert
