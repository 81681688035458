import { TOKENS_TO_REFRESH } from '@constants'
import { toRefresh } from '@utils/tokens'

export const refreshToken = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  headers,
  token,
}: {
  apiUrl?: string
  headers: Headers
  token: string
}): Promise<string> => {
  if (!token?.length || !headers) return null
  headers.set('X-REFRESH-TOKEN', token)
  const res = await fetch(`${apiUrl}/jwt/refresh`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers,
  })
  if (!res?.ok) throw Error
  const rep = await res.json()
  if (!rep?.token || rep?.error)
    throw new Error(rep?.error || 'Error fetching refresh token')
  return rep.token
}

export const refreshTokens = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  headers,
  tokens,
}: {
  apiUrl?: string
  headers: Headers
  tokens: JWT
}): Promise<JWT> => {
  if (!tokens || !headers) return null
  const _tokens = { ...tokens }
  const expiredTokens = toRefresh({
    tokenData: _tokens,
    tokensToRefresh: TOKENS_TO_REFRESH,
  })
  if (expiredTokens.length) {
    await Promise.all(
      expiredTokens.map(async ([name, token]) => {
        const newToken = await refreshToken({
          apiUrl,
          headers,
          token,
        })
        if (newToken?.length) _tokens[name] = newToken
      })
    )
    return _tokens
  }
  return null
}
