import { refreshTokens } from '@mw/tokens'
import { DEFAULT_LOCALES } from '@constants'

interface SessionResponse {
  data: Session
  cookies?: string[] | null
}

export const fetchSession = async (
  {
    apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
    locale,
    headers,
    query,
  }: {
    apiUrl?: string
    locale: string
    headers?: Headers
    query?: string
  } = {
    apiUrl: process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
    locale: DEFAULT_LOCALES,
  }
): Promise<SessionResponse> => {
  const res = await fetch(
    `${apiUrl}/v1/session?locale=${locale}${query || ''}`,
    {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers,
    }
  )
  if (!res?.ok) throw new Error(`ERROR: API session failed`)
  const { data } = await res.json()
  const _data = Array.isArray(data) ? data[0] : data
  const cookies = (res.headers as any)?.raw?.()['set-cookie'] || null
  if (_data?.tokens) {
    const newTokens = await refreshTokens({
      apiUrl,
      headers,
      tokens: _data.tokens,
    })
    if (newTokens) _data.tokens = newTokens
  }
  return { data: _data, cookies }
}

export const updateSession = async ({
  apiUrl = process.env.NEXT_PUBLIC_SMALLABLE_API_URL || '',
  locale,
  headers,
  query,
  ...toUpdate
}: {
  apiUrl?: string
  locale: string
  headers?: Headers
  query?: string
  currencyCode?: string
  deliveryCountryCode?: string
}): Promise<SessionResponse> => {
  const res = await fetch(
    `${apiUrl}/v1/session?locale=${locale}${query || ''}`,
    {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: headers || { 'Content-Type': 'application/json' },
      body: JSON.stringify(toUpdate),
    }
  )
  if (!res?.ok) throw new Error(`ERROR: API session failed`)
  const { data } = await res.json()
  const _data = Array.isArray(data) ? data[0] : data
  const cookies = (res.headers as any)?.raw?.()['set-cookie'] || null
  return { data: _data, cookies }
}
