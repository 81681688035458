import { FC } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useUserSettings } from '@context/session'
import { useUI } from '@components/ui/context'
import { isMobile } from 'react-device-detect'

import { fetchLogin } from '@mw/login'
import { setCookie } from '@utils/cookies'
import { changeLocaleIfTranslatable } from '@utils/locale'

import { Button } from '@components/ui'

import s from './WithFacebook.module.css'
import { Facebook } from '@components/icons'

interface WithFacebookProps {
  referer?: string
  isLogin?: boolean
  isModal?: boolean
}

interface GetLoginStatusResponse {
  authResponse?: {
    accessToken?: string
    userID?: string
    expiresIn?: number
    signedRequest?: string
    graphDomain?: string
    data_access_expiration_time?: number
  }
  status?: string
  loginSource?: string
}

const WithFacebook: FC<WithFacebookProps> = ({
  referer,
  isLogin = true,
  isModal = false,
}) => {
  const { locale } = useRouter()
  const router = useRouter()
  const { t } = useTranslation()
  const { closeModal } = useUI()
  const { refreshSession, tokens, deliveryCountryCode } = useUserSettings()
  const anonymousToken = tokens?.['X-ANONYMOUS-TOKEN'] || ''

  const setFbConnectCookie = (fbLoginInfo) => {
    if (!fbLoginInfo) return null
    setCookie('SML_FB_CONNECT', JSON.stringify(fbLoginInfo), 1)
  }

  const handleFBClick = async () => {
    let fbInfos = null
    if (isMobile) {
      window.FB.login(
        async (response: GetLoginStatusResponse) => {
          if (response?.status === 'connected') {
            window.FB.api(
              '/me',
              { fields: 'email, last_name, first_name' },
              (responseApi) => {
                fbInfos = responseApi || null
                handleFBFetchLogin(response, anonymousToken, fbInfos)
              }
            )
          } else if (response?.authResponse) {
            router.push(`/register`),
              undefined,
              {
                locale: changeLocaleIfTranslatable(locale),
              }
          }
        },
        { scope: 'email' }
      )
    } else {
      window?.FB?.getLoginStatus(
        async (response: GetLoginStatusResponse) => {
          if (response.status === 'connected') {
            window.FB.api(
              '/me',
              { fields: 'email, last_name, first_name' },
              (responseApi) => {
                fbInfos = responseApi || null
                handleFBFetchLogin(response, anonymousToken, fbInfos)
              }
            )
          } else {
            window.FB.login(
              async (response: GetLoginStatusResponse) => {
                if (response?.status === 'connected') {
                  window.FB.api(
                    '/me',
                    { fields: 'email, last_name, first_name' },
                    (responseApi) => {
                      fbInfos = responseApi || null
                      handleFBFetchLogin(response, anonymousToken, fbInfos)
                    }
                  )
                } else if (response?.authResponse) {
                  window.location.href = `/${changeLocaleIfTranslatable(
                    locale
                  )}/register/`
                }
              },
              { scope: 'email' }
            )
          }
        },
        { scope: 'email' },
        true
      )
    }
  }

  const handleFBFetchLogin = async (
    response: GetLoginStatusResponse,
    anonymousToken: string,
    fbInfos?: any,
    deliveryCountryCode?: string
  ) => {
    if (!response) return null
    const formData = {
      accessToken: response.authResponse?.accessToken,
      type: 'facebook',
    }
    try {
      const repLogin = await fetchLogin({
        formData,
        anonymousToken,
        country: deliveryCountryCode,
      })
      if (repLogin?.status === 500) throw new Error('Internal server error')
      if (repLogin.status === 200) {
        if (!isModal) {
          if (referer?.includes('/basket/'))
            window.location.href = `/${changeLocaleIfTranslatable(
              locale
            )}/payment/`
          else
            window.location.href =
              referer || `/${changeLocaleIfTranslatable(locale)}/account/`
        } else {
          refreshSession()
          closeModal()
        }
      } else if (
        repLogin?.status === 400 ||
        repLogin?.status === 401 ||
        repLogin?.status === 405
      ) {
        if (fbInfos) setFbConnectCookie(fbInfos)
        router.push(`/register`),
          undefined,
          {
            locale: changeLocaleIfTranslatable(locale),
          }
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className={s.btnFacebookContainer}>
      <p>
        {isLogin
          ? t('layout:header.modalLogin.facebook.divider')
          : t('layout:header.modalLogin.facebook.dividerRegister')}
      </p>
      <Button onClick={handleFBClick} className={s.btnFacebook} icon={Facebook}>
        FACEBOOK
      </Button>
    </div>
  )
}

export default WithFacebook
