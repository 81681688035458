import { FC } from 'react'
import { useUserSettings } from '@context/session'
import useTranslation from 'next-translate/useTranslation'

import Link from '@components/ui/Link'
import MediaQueries from '@components/common/MediaQueries'

import s from './Reassurance.module.css'
import cn from 'classnames'
import Carousel from '../Carousel/Carousel'

interface ReassuranceProps {
  className?: string
}

const Reassurance: FC<ReassuranceProps> = ({ className }) => {
  const { t } = useTranslation()
  const { deliveryCountryCode } = useUserSettings()

  const deliveryIsInFrance: boolean = deliveryCountryCode === 'FR'

  return (
    <>
      <MediaQueries hidden={['xs', 'sm', 'md']}>
        <div className={cn(className, s.container)}>
          <div className={s.column}>
            <h2 className={s.title}>
              {t('checkout:paymentInfo.needHelp.title')}
            </h2>
            <p className={s.text}>
              {t('checkout:paymentInfo.needHelp.contactForm')}&nbsp;
              <Link
                href={t('checkout:paymentInfo.needHelp.linkForm')}
                aria-label={t('checkout:paymentInfo.needHelp.textLinkForm')}
                className={s.link}
              >
                {t('checkout:paymentInfo.needHelp.textLinkForm')}&nbsp;
              </Link>
              {t('checkout:paymentInfo.needHelp.text2')}&nbsp;
              <Link
                href={`tel:${t('checkout:paymentInfo.needHelp.tel')}`}
                aria-label={t('checkout:paymentInfo.needHelp.ariaTel')}
                className={s.link}
              >
                {t('checkout:paymentInfo.needHelp.tel')}
              </Link>
            </p>
          </div>
          <div className={s.column}>
            <h2 className={s.title}>
              {deliveryIsInFrance ? (
                <>{t('checkout:paymentInfo.refund.title')}</>
              ) : (
                <>{t('checkout:paymentInfo.customsFees.title')}</>
              )}
            </h2>
            <p className={s.text}>
              {deliveryIsInFrance ? (
                <>{t('checkout:paymentInfo.refund.text')}</>
              ) : (
                <>
                  {t('checkout:paymentInfo.customsFees.text')}&nbsp;
                  <Link
                    next={false}
                    className={s.link}
                    href={t('checkout:paymentInfo.linkDeliveryPage')}
                    aria-label={`${t('layout:common.ariaNavigate')} ${t(
                      'checkout:paymentInfo.deliveryPage'
                    )}`}
                  >
                    {t('checkout:paymentInfo.customsFees.moreInfo')}
                  </Link>
                </>
              )}
            </p>
          </div>
          <div className={s.column}>
            <h2 className={s.title}>
              {t('checkout:paymentInfo.paymentSecure.title')}
            </h2>
            <p className={s.text}>
              {t('checkout:paymentInfo.paymentSecure.text')}
            </p>
          </div>
        </div>
      </MediaQueries>
      <MediaQueries visible={['xs', 'sm', 'md']}>
        <Carousel>
          <div className={s.carouselItem}>
            <h2 className={s.title}>
              {t('checkout:paymentInfo.needHelp.title')}
            </h2>
            <p className={s.text}>
              {t('checkout:paymentInfo.needHelp.text1')}&nbsp;
              <Link
                href={`tel:${t('checkout:paymentInfo.needHelp.tel')}`}
                aria-label={t('checkout:paymentInfo.needHelp.ariaTel')}
                className={s.link}
              >
                {t('checkout:paymentInfo.needHelp.tel')}
              </Link>
              &nbsp;{t('checkout:paymentInfo.needHelp.text2')}&nbsp;
              <Link
                next={false}
                href={t('checkout:paymentInfo.needHelp.emailTarget')}
                aria-label={t('checkout:paymentInfo.ariaContactUs')}
                className={s.link}
              >
                {t('checkout:paymentInfo.needHelp.email')}
              </Link>
              {t('checkout:paymentInfo.needHelp.text3')}
            </p>
          </div>
          <div className={s.carouselItem}>
            <h2 className={s.title}>
              {deliveryIsInFrance ? (
                <>{t('checkout:paymentInfo.refund.title')}</>
              ) : (
                <>{t('checkout:paymentInfo.customsFees.title')}</>
              )}
            </h2>
            <p className={s.text}>
              {deliveryIsInFrance ? (
                <>{t('checkout:paymentInfo.refund.text')}</>
              ) : (
                <>
                  {t('checkout:paymentInfo.customsFees.text')}&nbsp;
                  <Link
                    next={false}
                    className={s.link}
                    href={t('checkout:paymentInfo.linkDeliveryPage')}
                    aria-label={`${t('layout:common.ariaNavigate')} ${t(
                      'checkout:paymentInfo.deliveryPage'
                    )}`}
                  >
                    {t('checkout:paymentInfo.customsFees.moreInfo')}
                  </Link>
                </>
              )}
            </p>
          </div>
          <div className={s.carouselItem}>
            <h2 className={s.title}>
              {t('checkout:paymentInfo.paymentSecure.title')}
            </h2>
            <p className={s.text}>
              {t('checkout:paymentInfo.paymentSecure.text')}
            </p>
          </div>
        </Carousel>
      </MediaQueries>
    </>
  )
}

export default Reassurance
