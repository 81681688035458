import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import { Arrow } from 'components/ui'

import cn from 'classnames'
import s from './CurrencySelector.module.css'

export interface CurrencySelectorProps {
  className?: string
  currencies: Array<Currency>
}

const CurrencySelector: FC<CurrencySelectorProps> = ({
  className,
  currencies,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { currencyCode, selectCurrency } = useUserSettings()

  return (
    <div className={cn(s.selectorContainer, className)}>
      <div
        className={s.valueSelect}
        aria-label={t('layout:header.currency.ariaCurrency', {
          currency: currencyCode || '',
        })}
      >
        <span className={s.value}>{currencyCode}</span>
        <Arrow
          width="8"
          height="8"
          stroke="white"
          className={s.arrowDown}
          aria-hidden
        />
      </div>
      <select
        className={cn(s.selectField)}
        onChange={(e) => selectCurrency(e.target.value, locale)}
        value={currencyCode}
        aria-label={t('layout:header.currency.ariaCurrency')}
      >
        {currencies?.map((currency) => (
          <option value={currency.isoCode} key={currency.id}>
            {currency.isoCode}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CurrencySelector
